export default {
	buttons: {
		prev: 'Back',
		next: 'Next',
	},
	title: 'Onboarding',
	subtitle:
		'Please review the essential information about the semester before accessing the dashboard.',
	onboarded: {
		firstSemester: {
			title: 'Onboard',
			submit: 'Personality test',
		},
		secondSemester: {
			title: 'Onboard',
			submit: 'Finish',
			owner: 'For project owners',
			member: 'For HSUP students',
		},
	},
};