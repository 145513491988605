import palette from '../palette';

export default {
	root: {},
	notchedOutline: {
		borderColor: palette.text.muted,
	},
	input: {
		color: palette.secondary.main,
		'&::placeholder': { color: palette.text.primary },
	},
};
