import { colors } from '@material-ui/core';
import palette from '../palette';

export default {
	root: {
		fontFamily: 'REM',
		fontSize: '14px',
		fontWeight: 500,
		lineHeight: '16px',
		boxShadow: 'none',
		borderRadius: 2,
		padding: '20px 32px',
		border: '2px solid transparent',
	},
	sizeLarge: {
		fontSize: '14px',
		fontWeight: 700,
		lineHeight: '16px',
		padding: '22px',
	},
	contained: {
		backgroundColor: palette.ternary.main,
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: palette.ternary.dark,
			boxShadow: 'none',
		},
		'&.Mui-disabled': {
			backgroundColor: colors.blueGrey[100],
			color: 'white',
		},
	},
	outlined: {
		padding: '20px 32px',
		borderColor: palette.ternary.main,
		borderWidth: 2,
		color: palette.secondary.main,
		'&:hover': {
			borderWidth: 2,
		},
		'&.Mui-disabled': {
			borderColor: palette.ternary.main,
			borderWidth: 2,
			opacity: '50%',
		},
	},
};
