import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { RootState } from 'app/store/store';
import type {
	StartupLabEvent,
	StartupLabEventCategory,
} from 'features/startup-lab-event/type/StartupLabEvent.type';
import { genericRefreshToast } from '../../toast';
import { updateStartupLabEvent } from './manageStartupLabEventThunks';

const baseUrl = '/startup-lab';

type StartupLabEventPagination = {
	totalCount: number | null;
	pageCount: number | null;
	pageNumber: number;
};

export type StartupLabEventFilters = {
	search: string;
	categories: StartupLabEventCategory[] | null;
};

type StartupLabEventsState = {
	isPending: boolean;
	startupLabEvents: StartupLabEvent[] | null;
	pagination: StartupLabEventPagination;
	filters: StartupLabEventFilters;
};

const initialState: StartupLabEventsState = {
	isPending: false,
	startupLabEvents: null,
	pagination: {
		totalCount: null,
		pageCount: null,
		pageNumber: 1,
	},
	filters: {
		search: '',
		categories: null,
	},
};

type GetStartupLabEventsParams = {
	pageNumber?: number;
	filters?: StartupLabEventFilters;
};
export const getStartupLabEvents = createAsyncThunk(
	'startup-lab-events/getStartupLabEvents',
	async (params: GetStartupLabEventsParams = {}, thunkApi) => {
		const { getState, dispatch } = thunkApi;
		const { pageNumber } = params;

		const rootState = getState() as RootState;

		const state = rootState.startupLabEvents;
		const filters = params.filters ?? state.filters;

		// prevent multiple search requests
		if (state.isPending) {
			return null;
		}

		// if action triggered by filter update, save it in store
		// on page change, filters are sent from store
		if (params.filters) {
			dispatch(updateCachedFilters(params.filters));
		}

		// block further searches until action finishes
		dispatch(startPending());

		try {
			const search = filters?.search ?? null;
			const { categories } = filters;

			const requestBody = {
				categories,
				search,
				pageNumber: pageNumber ?? state.pagination.pageNumber,
			};

			const response = await axiosService.instance.post(`${baseUrl}/find-all`, requestBody);

			const {
				startupLabEvents,
				pagination,
			}: {
				startupLabEvents: StartupLabEvent[];
				pagination: StartupLabEventPagination;
			} = response.data;

			return { startupLabEvents, pagination };
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const startupLabEventsSlice = createSlice({
	name: 'startupLabEvents',
	initialState,
	reducers: {
		startPending(state) {
			state.isPending = true;
		},
		updateCachedFilters(state, action) {
			state.filters = action.payload;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(getStartupLabEvents.fulfilled, (state, action) => {
			// cancelled request
			if (action.payload === null) {
				return;
			}

			state.isPending = false;

			const { startupLabEvents, pagination } = action.payload;

			state.pagination = pagination;
			state.startupLabEvents = startupLabEvents;
		});
		builder.addCase(updateStartupLabEvent.fulfilled, (state, action) => {
			state.startupLabEvents = (state.startupLabEvents as StartupLabEvent[]).map((item) => {
				if (item._id === action.payload._id) {
					return action.payload;
				}
				return item;
			});
		});
	},
});

export const { startPending, updateCachedFilters } = startupLabEventsSlice.actions;

export const selectStartupLabEventList = (state: RootState) =>
	state.startupLabEvents.startupLabEvents;

export const selectStartupLabEventPaginationInfo = (state: RootState) =>
	state.startupLabEvents.pagination;

export const selectStartupLabEventResultSize = (state: RootState) =>
	state.startupLabEvents.pagination.totalCount ?? null;

export const selectStartupLabEventIsPending = (state: RootState) =>
	state.startupLabEvents.isPending;

export default startupLabEventsSlice.reducer;
