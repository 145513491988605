const mentorI18nHu = {
	findMentor: {
		header: {
			title: 'Mentor search',
			STUDENT: {
				subtitle:
					"Only the project owner has access to the mentor selection feature. Each team can have only one mentor. You can apply to multiple mentors, but the one who accepts your application first will become your team's mentor.",
			},
			OTHER: {
				subtitle: 'Mentors participating in HSUP',
			},
		},
		noResult: {
			text1: "We couldn't find a mentor based on the given criteria.",
			text2: 'Please expand the filtering criteria..',
		},
	},
	mentorApplications: {
		header: {
			title: 'My applications',
			subtitle: 'Mentors you have applied to.',
		},
		emptyState: {
			title: 'You have not applied to a mentor yet.',
			subtitle:
				"Take a look at the mentor list to make sure the mentor that suits you best isn't taken. It's important to know that you can only have one mentor, but you can apply to multiple mentors. The mentor who accepts your application first will be your mentor.",
			primaryButton: 'Mentor search',
		},
		hasMentor: {
			title: 'Congratulations! Now you have a mentor!',
			subtitle: 'You can find their contact details in the Team section..',
			primaryButton: 'View my Team',
		},
		card: {
			revokeButton: 'Revoke application',
			fullButton: 'No more slots at the mentor',
			noProject: 'You don’t have a project yet',
		},
		revokeDialog: {
			title: 'Are you sure you want to withdraw your application? ',
			subtitle:
				"If you withdraw your application, it will be removed from the mentor's view on the platform, and they will no longer be able to accept it later.",
			cancel: 'Cancel',
			submit: 'Submit',
		},
		emptyStateMentor: {
			publishNeeded: {
				title: 'First publish your data!',
				description: 'To allow students to apply to you, you first need to complete your personal description.',
			},
			missingApplications: {
				title: 'Currently no applicants!',
				description: 'Once a student applies to you, they will appear here.',
			}
		}
	},
	manageMentor: {
		title: 'Project applications',
		subtitle: '<h5>You can still mentor <green>{{count}} projects</green>.</h5>',
		acceptDialog: {
			title: 'Are you sure you want to accept the invitation?',
			subtitle:
				"A project team can only have one mentor, so if you accept the invitation, the team will not be able to change this later.",
			cancel: 'Cancel',
			submit: 'Accept',
		},
		rejectDialog: {
			title: 'Are you sure you want to reject the invitation?',
			subtitle:
				'Once you decline a team, they will no longer be able to apply to join you.',
			placeholder: 'Reason (Maximum 1000 characters)',
			cancel: 'Cancel',
			submit: 'Decline',
			reason: 'Reason',
		},
		applicationsCard: {
			motivation: 'Cover letter',
			project: 'Project',
			view: 'View',
		}
	},
	applyMentor: {
		title: 'Mentor application',
		lead: "The mentor can view your project's data sheet, but you can also write a personal message to them. Based on the application, the mentor can accept or decline your application. Before finalizing your application, remember that you can only choose one mentor for your team!",
		motivation: {
			title: 'Introduce yourself and your team!!',
			label: 'Why do you think the selected mentor would be the best fit for you? Share your motivation. ',
			placeholder: 'Start typing',
		},
		submit: 'I apply to the mentor',
		applySuccessMessage: 'Successful application',
		applyBeforeProject: 'First, publish your project description!',
		mentorFull: 'This mentor cannot take on more projects!',
		alreadyHasMentor: 'This project already has a mentor, please choose another one!',
		revokeSuccessMessage: 'Successful withdrawal',
	},
};

export default mentorI18nHu;

