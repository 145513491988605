export default {
	title: 'Progress report',
	subtitle: 'Project owners summarise the work of the semester in the progress report.',
	whatIsProgressReport: 'What is a progress report?',
	stepsTitle: 'Upload Progress report',
	stepsSubtitle: ' Deadline for uploading:',
	modules: 'Modules',
	didYouKnowTitle: 'Did you know?',
	didYouKnowDescription1:
		"The progress report is a written document summarising the teams' second semester assignments, supplemented by their personal impressions.",
	didYouKnowDescription2:
		' In this document, the team will also have to report on where they started from and where they have got to during the semester. The report can be uploaded to the HSUP interface by the project owner after they have completed all the modules.',
	didYouKnowDescription3:
		"In all cases, the progress report will be sent to the project leader's supervisor, even if the team is composed of students from different universities.",
	steps: {
		1: ' Download the HSUP Progress report template!',
		downloadTemplate: 'Download template',
		2: "Fill in the blanks of the template with a summary of the semester's work!",
		3: 'Upload the completed template!',
		4: 'Click on the "UPLOAD REPORT" button!',
		uploadHere: 'Click to upload or drag and drop the file here!',
		acceptedExtensions: ' Upload the file in pdf format (max 5 mb)',
		uploadReport: 'UPLOAD REPORT ',
	},
	alreadyUploaded: {
		title: 'You have successfully uploaded the Progress report!',
		description: 'Click on the button below to view the uploaded version.',
		buttonLabel: 'report download',
	},
	completeSubjectFirst: {
		title: 'Complete all modules first!',
		description:
			'You cannot upload your Progress Report at the moment. Complete all modules and come back!',
	},
};
