import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from '../axiosService';

export const fetchContext = createAsyncThunk('fetchContext', async (_, thunkAPI) => {
	const { data: context } = await axiosService.instance.get('/context');
	thunkAPI.dispatch(setContext(context));
});

const contextSlice = createSlice({
	name: 'context',
	initialState: { data: null },
	reducers: {
		setContext: (state, action) => {
			state.data = action.payload;
		},
	},
});

export const { setContext } = contextSlice.actions;

export const getContext = (state) => state.context.data;

export const findUniversityFullNameFromShort = (state, universityShort) =>
	getContext(state)?.UNIVERSITY_NAMES[universityShort] ?? null;

export const getUniversityFromShort = (universityShort) => (state) =>
	getContext(state)?.UNIVERSITY_NAMES[universityShort] ?? '';

export default contextSlice.reducer;
