import { LAYOUT_TYPES, URL_PREFIXES, USER_TYPES } from 'app/constants';
import { getUser } from 'app/store/slices/auth.slice';
import { getContext } from 'app/store/slices/context.slice';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export function useRouterGuard(layoutType: keyof typeof LAYOUT_TYPES) {
	const history = useHistory();
	const user = useSelector(getUser);
	const context = useSelector(getContext);

	// Checking whether there is a user logged in
	if (!user) {
		return layoutType !== LAYOUT_TYPES.GUEST
			? history.push(`${URL_PREFIXES.AUTH}/login`)
			: null;
	}

	// Checking whether the user needs to be onboarded
	if (user.role === USER_TYPES.STUDENT && user.onboarded[context.CURRENT_ONBOARD_KEY] === false) {
		return layoutType !== LAYOUT_TYPES.ONBOARDING
			? history.push(`${URL_PREFIXES.ONBOARDING}`)
			: null;
	}

	// Checking whether the user needs to have a personality
	if (user.role === USER_TYPES.STUDENT && !user.personality) {
		return layoutType !== LAYOUT_TYPES.PERSONALITY
			? history.push(`${URL_PREFIXES.PERSONALITY}/welcome`)
			: null;
	}

	switch (user.role) {
		case USER_TYPES.STUDENT:
			return layoutType !== LAYOUT_TYPES.ENROLLED ? history.push(URL_PREFIXES.STUDENT) : null;
		case USER_TYPES.MENTOR:
			return layoutType !== LAYOUT_TYPES.MENTOR ? history.push(URL_PREFIXES.MENTOR) : null;
		case USER_TYPES.OFFICE:
			return layoutType !== LAYOUT_TYPES.OFFICE ? history.push(URL_PREFIXES.OFFICE) : null;
		case USER_TYPES.UNIVERSITY:
			return layoutType !== LAYOUT_TYPES.UNIVERSITY
				? history.push(URL_PREFIXES.UNIVERSITY)
				: null;
		default:
			return null;
	}
}
