import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
	FLUSH,
	PAUSE,
	PERSIST,
	persistReducer,
	persistStore,
	PURGE,
	REGISTER,
	REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
	authReducer,
	contextReducer,
	corporateProblemsReducer,
	findStudentReducer,
	lessonContentsReducer,
	loaderReducer,
	manageMentorApplicationsReducer,
	memberInvitationReducer,
	moduleTestReducer,
	myProjectReducer,
	newsReducer,
	personalityTestReducer,
	progressReducer,
	projectMemberFinderReducer,
	pushNotificationReducer,
	registrationConfirmationsReducer,
	statisticsReducer,
	suggestionReducer,
} from './slices';
import { findMentorReducer, mentorApplicationReducer } from './slices/mentor';
import { onePagerListReducer, onePagerUploadReducer } from './slices/one-pager';
import { progressReportListReducer, progressReportUploadReducer } from './slices/progress-report';
import { findProjectReducer } from './slices/project';
import { startupLabEventsReducer } from './slices/startup-lab-event';
import { startupLabEventDetailsReducer } from './slices/startup-lab-event-details';

const authPersistConfig = {
	key: 'hsup',
	storage,
	whitelist: ['token'],
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);

const store = configureStore({
	reducer: {
		auth: persistedAuthReducer,
		loader: loaderReducer,
		progress: progressReducer,
		statistics: statisticsReducer,
		moduleTests: moduleTestReducer,
		lessonContents: lessonContentsReducer,
		personalityTest: personalityTestReducer,
		suggestion: suggestionReducer,
		myProject: myProjectReducer,
		context: contextReducer,
		findProject: findProjectReducer,
		findMentor: findMentorReducer,
		findStudent: findStudentReducer,
		mentorApplication: mentorApplicationReducer,
		manageMentorApplications: manageMentorApplicationsReducer,
		progressReportUpload: progressReportUploadReducer,
		progressReportList: progressReportListReducer,
		startupLabEvents: startupLabEventsReducer,
		startupLabEventDetails: startupLabEventDetailsReducer,
		news: newsReducer,
		pushNotification: pushNotificationReducer,
		corporateProblems: corporateProblemsReducer,
		onePagerList: onePagerListReducer,
		onePagerUpload: onePagerUploadReducer,
		projectMemberFinder: projectMemberFinderReducer,
		memberInvitation: memberInvitationReducer,
		registrationConfirmation: registrationConfirmationsReducer,
	},
	middleware: getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		},
	}),
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export { persistor, store };

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
