import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { RootState } from 'app/store/store';
import { genericRefreshToast } from 'app/store/toast';
import type { StartupLabEvent } from 'features/startup-lab-event/type/StartupLabEvent.type';

const baseUrl = '/startup-lab';

type StartupLabEventDetailsState = {
	startupLabEvent: StartupLabEvent | null;
};

const initialState: StartupLabEventDetailsState = {
	startupLabEvent: null,
};

type FindStartupLabEventDetailsParams = {
	startupLabEventId: string;
};

export const findStartupLabEventDetails = createAsyncThunk(
	'startup-lab-events/find-details',
	async ({ startupLabEventId }: FindStartupLabEventDetailsParams) => {
		try {
			const response = await axiosService.instance.get(`${baseUrl}/${startupLabEventId}`);
			const startupLabEvent: StartupLabEvent = response.data;

			return { startupLabEvent };
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const startupLabEventDetailsSlice = createSlice({
	name: 'startupLabEventDetails',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(findStartupLabEventDetails.pending, (state) => {
			state.startupLabEvent = null;
		});
		builder.addCase(findStartupLabEventDetails.fulfilled, (state, action) => {
			const { startupLabEvent } = action.payload;
			state.startupLabEvent = startupLabEvent;
		});
	},
});

export default startupLabEventDetailsSlice.reducer;

export const selectStartupLabEventDetails = (state: RootState) =>
	state.startupLabEventDetails.startupLabEvent;
