import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { errorToast, successToast } from 'app/store/toast';
import i18n from 'i18next';
import { findUniversityFullNameFromShort, setContext } from './context.slice';

export const fetchUser = createAsyncThunk('fetchUser', async (_, thunkAPI) => {
	const { data: user } = await axiosService.instance.get('/auth/user');
	thunkAPI.dispatch(setContext(user.context));
	return { user };
});

export const loginUser = createAsyncThunk(
	'loginUser',
	async ({ email, password, setSubmitting }, thunkAPI) => {
		try {
			const { data } = await axiosService.instance.post('/auth/login', { email, password });
			const { user, token, context } = data;
			thunkAPI.dispatch(setContext(context));
			axiosService.refreshRequestHandler(token);
			return { user, token };
		} catch (error) {
			setSubmitting(false);
			if (error.response.status === 401) {
				errorToast(i18n.t('auth.pages.login.wrongCredentials'));
			} else if (error.response.status === 500) {
				errorToast(i18n.t('auth.pages.login.networkError'));
			} else {
				errorToast(i18n.t('auth.pages.login.otherError'));
			}
			throw error;
		}
	}
);

export const forgotPassword = createAsyncThunk('forgotPassword', async ({ email, history }) => {
	try {
		await axiosService.instance.post('/auth/forgot-password', { email });
	} catch (error) {
		console.log(error); // eslint-disable-line no-console
	} finally {
		history.push('/auth/forgot-password/success');
	}
});

export const changePasswordWithToken = createAsyncThunk(
	'changePasswordWithToken',
	async ({ token, password, history }) => {
		try {
			await axiosService.instance.post(`/auth/change-password/${token}`, { password });
			history.push('/auth/change-password/success');
		} catch (error) {
			history.push('/auth/change-password/failed');
		}
	}
);

export const changePasswordWithCurrentPassword = createAsyncThunk(
	'changePasswordWithCurrentPassword',
	async ({ token, currentPassword, newPassword, resetForm }) => {
		try {
			await axiosService.instance.post(`/auth/change-password/${token}`, {
				currentPassword,
				newPassword,
			});
			successToast(i18n.t('auth.pages.changePassword.successHeader'));
		} catch (error) {
			if (error.response.data.message.includes('Current password is invalid')) {
				errorToast('Current password is incorrect');
				throw error;
			} else {
				errorToast(i18n.t('auth.pages.changePassword.failHeader'));
				throw error;
			}
		} finally {
			resetForm(); // reset loader and form inputs
		}
	}
);

export const updateMemberProfile = createAsyncThunk(
	'publishProfile',
	async ({ values, setSubmitting }) => {
		try {
			const { data: user } = await axiosService.instance.post(
				'/project-member/publish-profile',
				values
			);

			successToast(i18n.t('settings.projectMember.success'));

			return { user };
		} catch (error) {
			errorToast(i18n.t('settings.projectMember.fail'));
			throw error;
		} finally {
			setSubmitting(false);
		}
	}
);

export const registerUser = createAsyncThunk(
	'registerUser',
	async ({ accessToken, username, password, email, history }) => {
		try {
			await axiosService.instance.post(`/auth/register/${accessToken}`, {
				username,
				password,
				email,
			});
			history.push('/auth/register/success');
		} catch (error) {
			history.push('/auth/register/failed');
		}
	}
);

export const changePicture = createAsyncThunk(
	'changePicture',
	async ({ picture, setSubmitted }) => {
		try {
			const { data: user } = await axiosService.instance.post(
				`/auth/change-picture/`,
				picture
			);
			successToast(i18n.t('settings.personality.successMessage'));
			return { user };
		} catch (error) {
			return errorToast(i18n.t('settings.personality.errorMessage'));
		} finally {
			setSubmitted(true);
		}
	}
);

export const onboardUser = createAsyncThunk('onboardUser', async (setSubmitting) => {
	setSubmitting(true);
	try {
		const { data: onboarded } = await axiosService.instance.post('/auth/onboard');

		return { onboarded };
	} catch (error) {
		setSubmitting(false);
		throw error;
	}
});

const authSlice = createSlice({
	name: 'auth',
	initialState: { token: null, user: null },
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
		revertState: (state) => {
			state.user = null;
			state.token = null;
		},
		updatePersonality: (state, action) => {
			state.user.personality = action.payload.personality;
		},
	},
	extraReducers: {
		[fetchUser.fulfilled]: (state, action) => {
			state.user = action.payload.user;
		},
		[fetchUser.rejected]: (state) => {
			state.user = false;
		},
		[changePicture.fulfilled]: (state, action) => {
			state.user.profilePictureUrl = action.payload.user.profilePictureUrl;
		},
		[loginUser.fulfilled]: (state, action) => {
			const { user, token } = action.payload;
			state.user = user;
			state.token = token;
		},
		[onboardUser.fulfilled]: (state, action) => {
			const { onboarded } = action.payload;
			state.user.onboarded = onboarded;
		},
		[updateMemberProfile.fulfilled]: (state, action) => {
			state.user = action.payload.user;
		},
	},
});

export const { updatePersonality, setUser } = authSlice.actions;

export const getUser = (state) => state.auth.user;
export const getUniversityFullName = (state) =>
	findUniversityFullNameFromShort(state, state.auth?.user?.university);

export const getPersonality = (state) => getUser(state)?.personality;
export const getOnboarded = (state) => getUser(state)?.onboarded;

const { revertState } = authSlice.actions;

export const logoutUser = () => async (dispatch) => {
	window.localStorage.removeItem('persist:hsup');
	dispatch(revertState());
	axiosService.refreshRequestHandler('');
	window.location.replace('/');
};

export default authSlice.reducer;
