export default {
	buttons: {
		cancel: 'Cancel',
		ok: 'Ok',
		next: 'Next',
		previous: 'Previous',
		back: 'Back',
		forward: 'Next/Forward',
		close: 'Close',
		finish: 'Finish/Done',
		details: 'Details',
	},
	logout: 'Log out',
	contact: {
		email: 'E-mail address',
		mobile: 'phone number',
	},
	formats: {
		image: '.png, .jpg',
		pdf: '.pdf',
		spreadsheet: '.xls, .xlsx, .csv',
		document: '.doc, .docx',
	},
	filter: {
		title: 'Filtering',
		searchPlaceholder: 'Search by name...',
		clearSelection: 'Clear all conditions/filter',
		result: 'result',
		groups: {
			universities: 'Universities',
			focuses: 'Market Focus',
			specializations: 'Specializations',
			categories: 'Categories',
			corporateProblemIds: 'Partner challenges',
			personalities: 'Personalities',
			personality: 'Personality',
			status: 'status',
			onePager: 'One-pager',
			isUploaded: 'One-pager uploaded',
		},
		showLess: 'Less condition/filter',
		showMore: 'More conditions/filters',
	},
};
