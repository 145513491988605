import { AppBar, IconButton, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
	root: {
		boxShadow: 'none',
	},
	flexGrow: {
		flexGrow: 1,
	},
}));

const TopBar = ({ onOpenNavBarMobile }) => {
	const classes = useStyles();

	return (
		<AppBar className={classes.root} color="primary">
			<Toolbar>
				<RouterLink to="/">
					<img alt="Logo" src="./images/Logo.png" />
				</RouterLink>
				<div className={classes.flexGrow} />
				<IconButton onClick={onOpenNavBarMobile}>
					<MenuIcon />
				</IconButton>
			</Toolbar>
		</AppBar>
	);
};

TopBar.propTypes = {
	onOpenNavBarMobile: PropTypes.func.isRequired,
};

export default TopBar;
