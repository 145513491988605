export default {
	title: 'Partner challenges',
	subtitle:
		'This section contains the partner challenges. By clicking on the Upload new problem button, you can submit any number of problems for students. You may edit or delete them at any time during the semester.',
	add: {
		title: 'Upload a new partner challenge',
		subtitle: 'To submit a new challenge, click on the "upload new challenge" button!',
		publishButton: 'Upload new challenge',
	},
	dialog: {
		add: {
			title: 'Upload a new partner challenge',
			submitButton: 'Submit partner challenge',
		},
		next: 'Next',
		update: {
			title: 'Editing the partner challenge',
			submitButton: 'Publish change',
		},
		common: {
			subTitle: 'Basic data',
			title: {
				title: 'challenge title',
				titlePlaceholder: 'Dear students...',
			},
			logo: {
				logo: 'Company logo',
				logoPlaceholder: 'Click to upload image',
			},
			oneliner: {
				oneliner: 'short summary of the challenge (Max. {{count}} characters)',
				maxLenght: 'You can specify a maximum of {{count}} characters',
				onelinerPlaceholder: 'Dear students...',
			},
			description: {
				description: 'description of the challenge',
				descriptionPlaceholder: 'Dear students...',
			},
			limit: {
				limit: 'maximum number of students applying',
				limitPlaceholder: 'pl. 5',
			},
			docs: {
				docsTitle: 'Related documents',
				docsSubTitle: 'Selected documents',
				docsUploadButton: 'Upload new document',
				docsUploadSubtitle: 'Click or drag documents here for upload',
				docsFormatText: 'Formats to upload : ',
			},
		},
	},
	deleteDialog: {
		title: 'Are you sure you want to delete the partner challenge?',
		subtitle:
			'With deleting the challenge, all the data related to the challenge will be permanently deleted.',
		submitButton: 'Delete challenge',
	},
	item: {
		freeSlots: 'Available slots',
	},
	toast: {
		addError: 'Failed to upload challenge :(',
		addSuccess: 'Challenge uploaded successfully',
		updateError: 'Failed to change the challenge',
		updateSuccess: 'The challenge was successfully modified',
		deleteError: 'The challenge could not be deleted',
		deleteSuccess: 'The challenge was successfully deleted',
	},
};
