export default {
	pageTitle: 'Mobile notifications',
	title: 'Mobile notifications',
	subtitle:
		'Important information will be sent via push notification. Students who have downloaded the app and enabled notifications will receive it.',
	new: {
		title: 'Send new notification',
		subtitle:
			'Notifications are sent to students who have downloaded the app and enabled notifications',
		notificationTitle: 'notification title (max 50 characters)',
		notificationTitlePlaceholder: 'Dear students...',
		notificationContent: 'notification text (max 300 characters)',
		notificationContentPlaceholder: 'Dear students...',
		notificationRedirect: 'sub-page to which the notification will redirect the user in the application',
		notificationRedirectPlaceholder: 'Select a subpage!',
		sendNotification: 'Send notification',
		dialog: {
			title: 'Are you sure you want to send this notification to all students?',
			content:
				'Are you sure you want to send this notification to all students? This action cannot be undone.',
		},
	},
	older: {
		title: 'Previous notifications',
		redirectLabel: 'Subpage',
		sentTo: 'Sent out',
		toUser: 'to {{ number }} user',
	},
	redirectOptions: {
		dashboard: 'Dashboard',
		modules: 'Modules',
		myProject: 'My project',
		search: 'Search',
		moreOptions: 'News/Events',
	},
};
