/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import i18n from 'i18next';
import { errorToast, genericRefreshToast, successToast } from '../../toast';
import { calculateHasAcceptedMentors } from './mentor-utils';

const initialState = {
	applications: [],
	isLoading: false,
	hasAcceptedMentor: false,
};

export const applyToMentor = createAsyncThunk('mentor-application/apply', async (params = {}) => {
	const { mentorId, motivation, handleClose: closeModal, setIsLoading } = params;

	try {
		const requestUrl = `/mentor-application/apply/${mentorId}`;
		const requestBody = { motivation };
		const response = await axiosService.instance.post(requestUrl, requestBody);
		const mentorApplication = response.data;

		successToast(i18n.t('mentor.applyMentor.applySuccessMessage'));
		window.location.reload(); // reload page to get latest data, not elegant
		closeModal();

		return mentorApplication;
	} catch (error) {
		const message = error.response?.data?.message;
		const status = error.response?.data?.statusCode;
		let notification = null;

		if (status === 400) {
			if (message?.includes('is not a project owner')) {
				notification = i18n.t('mentor.applyMentor.applyBeforeProject');
			} else if (message?.includes('already reached max')) {
				notification = i18n.t('mentor.applyMentor.mentorFull');
			}
		}

		if (notification) {
			errorToast(notification);
		} else {
			genericRefreshToast();
		}

		throw error;
	} finally {
		setIsLoading(false);
	}
});

export const findMentorApplications = createAsyncThunk(
	'mentor-application/applications',
	async () => {
		try {
			const response = await axiosService.instance.get('/mentor-application/applications');
			const applications = response.data;
			return { applications };
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const revokeMentorApplication = createAsyncThunk(
	'mentor-application/revoke',
	async (params) => {
		const { applicationId, setIsLoading } = params;

		try {
			const response = await axiosService.instance.post(
				`/mentor-application/revoke/${applicationId}`
			);
			const deletedApplicationId = response.data;

			successToast(i18n.t('mentor.applyMentor.revokeSuccessMessage'));

			return { applicationId: deletedApplicationId };
		} catch (error) {
			genericRefreshToast();

			throw error;
		} finally {
			setIsLoading(false);
		}
	}
);

export const mentorApplicationSlice = createSlice({
	name: 'mentorApplication',
	initialState,
	reducers: {
		updateMentorApplications(state, action) {
			const applications = action.payload;
			state.applications = applications;
			state.hasAcceptedMentor = calculateHasAcceptedMentors(applications);
		},
	},
	extraReducers: {
		[applyToMentor.fulfilled]: (state, action) => {
			const mentorApplication = action.payload;
			state.applications.push(mentorApplication);
		},
		[findMentorApplications.pending]: (state) => {
			state.isLoading = true;
		},
		[findMentorApplications.fulfilled]: (state, action) => {
			const { applications } = action.payload;
			state.applications = applications;
			state.isLoading = false;
			state.hasAcceptedMentor = calculateHasAcceptedMentors(applications);
		},
		[findMentorApplications.rejected]: (state) => {
			state.isLoading = false;
		},
		[revokeMentorApplication.fulfilled]: (state, action) => {
			const { applicationId } = action.payload;
			state.applications = state.applications.filter(
				(application) => application._id !== applicationId
			);
		},
	},
});

export const { updateMentorApplications } = mentorApplicationSlice.actions;

export default mentorApplicationSlice.reducer;

export const selectMentorApplications = (state) => {
	return {
		itemList: state.mentorApplication.applications.map(mapMentorApplicationToListItem),
		isLoading: state.mentorApplication.isLoading,
	};
};

export const selectHasAcceptedMentor = (state) => state.mentorApplication.hasAcceptedMentor;

function mapMentorApplicationToListItem(mentorApplication) {
	return {
		id: mentorApplication._id,
		status: mentorApplication.status,
		motivation: mentorApplication.motivation,
		mentor: mentorApplication._mentor,
		project: mentorApplication._project,
	};
}
