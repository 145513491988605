import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from 'app/i18n';
import axiosService from 'app/store/axiosService';
import { errorToast, genericRefreshToast } from 'app/store/toast';
import { fetchUser } from '../auth.slice';

export const uploadOnePager = createAsyncThunk('uploadOnePager', async (formData) => {
	try {
		const { data } = await axiosService.instance.post('/one-pager', formData);
		return data;
	} catch (error) {
		errorToast(i18n.t('onePager.toast.addError'));
		throw error;
	}
});

export const fetchUploadedOnePager = createAsyncThunk('fetchOnePager', async () => {
	try {
		const { data } = await axiosService.instance.get('/one-pager');
		return data;
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const onePagerOnboard = createAsyncThunk(
	'onePagerOnboard',
	async (setSubmitting, thunkApi) => {
		try {
			setSubmitting(true);
			await axiosService.instance.post('/one-pager/onboard');

			thunkApi.dispatch(fetchUser());
		} catch (error) {
			genericRefreshToast();
			throw error;
		} finally {
			setSubmitting(false);
		}
	}
);

const onePagerUploadSlice = createSlice({
	name: 'onePagerUpload',
	initialState: {
		onePager: null,
		isOnePagerAvailable: false,
		showSuccessModal: false,
	},
	reducers: {
		setShowSuccessModal: (state, action) => {
			state.showSuccessModal = action.payload;
		},
	},
	extraReducers: {
		[uploadOnePager.pending]: (state) => {
			state.isOnePagerAvailable = false;
		},
		[uploadOnePager.fulfilled]: (state, action) => {
			state.onePager = action.payload;
			state.isOnePagerAvailable = true;
			state.showSuccessModal = true;
		},
		[fetchUploadedOnePager.pending]: (state) => {
			state.isOnePagerAvailable = false;
		},
		[fetchUploadedOnePager.fulfilled]: (state, action) => {
			state.onePager = action.payload;
			state.isOnePagerAvailable = true;
		},
	},
});

export const selectOnePager = ({ onePagerUpload: { onePager, isOnePagerAvailable } }) => ({
	onePager,
	isOnePagerAvailable,
});

export const selectShowSuccessModal = (state) => state.onePagerUpload.showSuccessModal;

export const { setShowSuccessModal } = onePagerUploadSlice.actions;

export default onePagerUploadSlice.reducer;
