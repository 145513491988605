const projectI18nHu = {
	title: 'Project',
	findProject: {
		header: {
			title: 'Project Search',
			subtitle: 'Browse the projects and join the team!',
		},
		card: {
			remaining: 'Free spots',
			count: '',
			full: 'Full',
		},
		noResult: {
			text1: 'No project found for the criteria you entered.',
			text2: 'Please expand the filtering criteria.',
		},
	},
	createProject: {
		title: 'My project',
		button: 'I prepare my project ',
		subtitle: 'To find the members of your team, prepare an introduction to your project.',
		cardTitle: 'Prepare a description of your project ',
		cardDescription:
			'Before you find future members of your team, you need to publish your project so that it is visible to others. As a first step, create your project by clicking the button below!',
		dialog: {
			title: 'Are you sure you want to publish your project description?',
			subtitle:
				"When you publish your project description, it becomes visible to everyone on the platform, so you can't edit it later.",
			cancel: 'Cancel',
			submit: 'Publish',
		},
	},
	notJoinedYet: {
		title: 'My project',
		button: 'Project finder',
		subtitle: 'Find your team!',
		cardTitle: 'You have not joined a team yet!',
		cardDescription:
			'Jump to the project finder and browse through the projects as you like, once you find one you like, apply! If you are accepted into a team, their details will appear here. Good luck!',
	},
	noMentoredProjectsYet: {
		cardTitle: 'You are not mentoring any projects yet!',
		cardDescription: 'Check if you have filled out your profile and wait for applicants!',
	},
	alreadyAppliedMember: {
		title: 'You are already a member of a project!',
		description:
			'Congratulations, a project owner has accepted your application. Check out your project and get in touch with your team! Good luck!',
	},
	projectApplications: {
		header: {
			title: 'Applications',
			subtitle:
				'You have applied for the following projects. The following requests are in pending status',
		},
		emptyState: {
			title: 'You have not yet applied for a project',
			subtitle:
				'You can apply for multiple projects, but you can only join one project team. If you have more than one project pending, you will be assigned to the team whose project owner accepts your application first. Have a look in the project search and choose the project you like the most. Hurry, as a maximum of 2 students can join a project.',
			primaryButton: 'Project search',
		},
		card: {
			projectOwner: 'Project owner',
			detailsButton: 'Details',
			revokeButton: 'Withdraw application',
		},
		revokeDialog: {
			title: 'Are you sure you want to withdraw your application?',
			subtitle:
				'If you withdraw your application, it will disappear from the platform for the host, so they will not be able to accept it later.',
			cancel: 'Cancel',
			submit: 'Submit',
		},
	},
	manageProject: {
		acceptDialog: {
			title: 'Are you sure you accept the applicant?',
			subtitle:
				'If you accept the applicant, you will not be able to remove him/her from your team later on, you will have to work with him/her during the semester.',
			cancel: 'Cancel',
			submit: 'Accept',
		},
		rejectDialog: {
			title: "Are you sure you're rejecting the applicant?",
			subtitle:
				'Once you reject an applicant, they will not be able to apply for your project again.',
			cancel: 'Cancel',
			submit: 'Reject',
		},
		noApplicationsScreen: {
			title: 'Currently no applicants!',
			subtitle:
				"Don't just wait for applicants, check out the Team Member Finder and invite the best candidates to join your team. If a candidate applies for your project, you'll see their profile here.",
			button: 'Team member finder',
		},
		cvTitle: 'Curriculum Vitae',
		missingCv: 'CV is missing',
		downloadCv: 'Download the CV',
	},
	applyProject: {
		title: 'Apply for the project',
		lead: 'You can apply for multiple projects, but you can only join one project team. If you have more than one project pending, you will be assigned to the team whose project owner accepts your application first.',
		motivation: {
			title: 'Introduce yourself!*',
			label: 'Describe why you want to work on the project and how you can help the team ',
			placeholder: ' Max. 1.000 characters ',
		},
		cvFile: {
			title: 'Upload CV (optional)',
		},
		submit: ' Apply for the project ',
		fileUpload: 'Choose files',
		modalSubmit: 'Apply',
		uploadFileFormats: 'Upload file formats',
		applySuccessMessage: 'Successful application!',
		applyErrorMessage: 'Unsuccessful application!',
		revokeSuccessMessage: 'Successful withdrawal!',
		revokerrorMessage: 'Unsuccessful withdrawal!',
	},
	team: {
		title: 'My team',
		titleStart: 'Still',
		titleFreeSeats: 'free spot(s)',
		titleEnd: 'left in your team.',
		titleFull: 'No more free space in the project.',
		subtitle:
			'A team can consist of a minimum of 2 and a maximum of 3 students. Tip: feel free to choose students from other universities and try to cover as many competences as possible in your team, as this will help you to be the most effective in the implementation of the project.',
		header: {
			title: 'Team members',
			subtitle:
				"Get involved, there's always competition for the best people. Find your team as soon as possible.",
		},
		emptyState: {
			member: {
				title: 'Team members',
				subtitle: 'No team members yet ',
				lead: "The second semester of HSUP is all about teamwork and the joint development of winning project ideas. If you haven't found a team member yet, check out the Team Member Search and watch the News Feed for events to support team building.",
				button: 'Applications',
			},
			mentor: {
				title: 'Mentor',
				subtitle: "You don't have a mentor yet ",
				lead: "Look around the mentor list to make sure you don't miss out on the best mentor for you. It's important to note that you can only have 1 mentor at this stage, but you can apply to more than one. The first one to accept your application will be your mentor.",
				button: 'Mentor search',
			},
		},
		noProjectScreen: {
			title: 'First publish the description of your project!',
			subtitle:
				'To find team members and mentors for your project, you must first publish a description of your project.',
			button: ' Project preparation ',
		},
	},
	mentorInterests: {
		header: {
			title: 'Interests',
			subtitle: {
				STUDENT:
					'Here are the mentors who are interested in your project and would like to mentor you during the semester.',
				MENTOR: 'Here you can see the list of projects for which you have submitted an interest.',
			},
		},
		emptyState: {
			STUDENT: {
				title: 'No mentor has yet shown interest in your project ',
				subtitle:
					'If a mentor likes your project, they can submit an interest and it will appear on this page',
			},
			MENTOR: {
				title: 'You have not yet submitted an interest in a project ',
				subtitle:
					'If you are interested in a project and would like to mentor the team, you can submit an expression of interest. Search for the projects you are most interested in using the project finder!',
				primaryButton: 'Project search',
			},
		},
		interestSuccessMessage: 'Successful enquiry!',
		buttons: {
			STUDENT: {
				alreadyApplied: 'You have already applied to this mentor',
				fullButton: 'no more spots',
			},
			MENTOR: {
				text: 'Interested in the project',
				disabled: ' Already interested in this project',
				active: ' Interested in the project ',
			},
		},
		noProjectScreen: {
			title: ' First publish the description of your project!',
			subtitle:
				' To get mentors interested in your project, you must first publish a presentation of your project.',
			button: 'Preparing a project',
		},
	},
	projectDetails: {
		motivation: 'Cover letter',
		presentation: 'Project description',
		presentationBody:
			'Your team members will know as much about your project as you share here. Try to introduce it as interesting as possible.',
		nameInputTitle: 'Project name',
		nameInputBody: 'Add the name of your project!',
		presentationInputTitle: 'Project short description',
		presentationInputBody: 'Summarize the essence of your project in a few sentences.',
		chooseFocusTitle: 'Select industrial focus',
		chooseFocusBody: 'You can select multiple!',
		problemTitle: 'Problem',
		solutionTitle: 'Solution',
		marketTitle: 'Market',
		competitorsTitle: 'Competitors',
		budinessModellTitle: 'Business model',
		aboutTeamTitle: 'About the team',
		aboutMeTitle: 'About me',
		contactTitle: 'Contact information',
		interest: {
			question: 'Project interest',
			description: 'Submit your interest in the project.',
			cancel: 'Cancel',
			apply: 'Submit interest',
		},
		mentor: 'Mentor',
		noMentorTitle: 'Do you need a mentor?',
		noMentorDescription: 'Need help? We connect you with a mentor',
		mentorListButton: 'Mentor list',
	},
	projectContact: {
		contact: 'My contact information',
		contactBody:
			"In order to contact your future team members, here you can add your contact information. Don't worry, this information will only be visible to the small team when you accept the applications for your project.",
		fullName: 'FULL NAME',
		university: 'UNIVERSITY',
		contactInfoTitle: 'Contact information',
		contanctInfoBody:
			'These data will only be visible to others when you have accepted the applications for your project.',
		contactInfoEmailTitle: 'E-mail address',
		contactInfoFacebookTitle: 'Facebook link',
		contactInfoLinkedInTitle: 'LinkedIn link',
		contactInfoPhoneTitle: 'Phone number',
		mandatoryText: 'The fields marked with * are mandatory',
	},
	publishProjectDialog: {
		question: 'Are you sure you want to publish your project description?',
		description:
			'If you publish your project description, it will be visible to everyone on the platform, so you will not be able to edit it later.',
	},
};

export default projectI18nHu;
