import { URL_PREFIXES } from 'app/constants';
import { EnrolledLayout } from 'app/layouts/User';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const { STUDENT } = URL_PREFIXES;

const studentRoutes = {
	path: STUDENT,
	component: EnrolledLayout,
	routes: [
		{
			path: `${STUDENT}`,
			exact: true,
			component: lazy(() => import('features/dashboard/student')),
		},
		{
			path: `${STUDENT}/news`,
			exact: true,
			component: lazy(() => import('features/news')),
		},
		// user can view personality detail page after taking the test too
		// but cannot retake test
		{
			path: `${STUDENT}/detail/:personality`,
			exact: true,
			component: lazy(() => import('features/personality-test/detail')),
		},
		{
			path: `${STUDENT}/lessons/:id`,
			exact: true,
			component: lazy(() => import('features/lesson/lesson')),
		},
		{
			path: `${STUDENT}/modules`,
			exact: true,
			component: lazy(() => import('features/progress/progress')),
		},
		{
			path: `${STUDENT}/my-project`,
			exact: true,
			component: lazy(() => import('features/project/my-project/my-project')),
		},
		{
			path: `${STUDENT}/my-project/manage-applications`,
			exact: true,
			component: lazy(() =>
				import('features/project/manage-applications/manage-applications')
			),
		},
		{
			path: `${STUDENT}/my-project/team`,
			exact: true,
			component: lazy(() => import('features/project/team/team')),
		},
		{
			path: `${STUDENT}/my-project/team/member-finder/list`,
			exact: true,
			component: lazy(() =>
				import('features/project-member-finder/find-members/find-members')
			),
		},
		{
			path: `${STUDENT}/my-project/team/member-finder/details/:id`,
			exact: true,
			component: lazy(() =>
				import('features/project-member-finder/find-members-details/find-member-details')
			),
		},
		{
			path: `${STUDENT}/my-project/team/member-finder/invitations`,
			exact: true,
			component: lazy(() =>
				import('features/project-member-finder/find-members/list/InvitedMemberList')
			),
		},
		// TODO: might be better to use feature-based routing
		{
			path: `${STUDENT}/project/project-detail/:projectId`,
			exact: true,
			component: lazy(() => import('features/project/project-detail')),
		},
		{
			path: `${STUDENT}/project/find-projects`,
			exact: true,
			component: lazy(() => import('features/project/find-projects')),
		},
		{
			path: `${STUDENT}/project/project-applications`,
			exact: true,
			component: lazy(() => import('features/project/project-applications')),
		},
		{
			path: `${STUDENT}/project/invitations`,
			exact: true,
			component: lazy(() =>
				import('features/project-member-finder/find-members/list/InvitationsForProjectList')
			),
		},
		// New mentor pages (Mentors for Student)
		{
			path: `${STUDENT}/mentors/find-mentors`,
			exact: true,
			component: lazy(() => import('features/mentor/find-mentors/find-mentors')),
		},
		{
			path: `${STUDENT}/mentors/mentor-applications`,
			exact: true,
			component: lazy(() =>
				import('features/mentor/mentor-applications/mentor-applications')
			),
		},
		{
			path: `${STUDENT}/mentors/mentor-interests`,
			exact: true,
			component: lazy(() => import('features/project/mentor-interests')),
		},
		{
			path: `${STUDENT}/settings`,
			exact: true,
			component: lazy(() => import('features/settings')),
		},
		{
			path: `${STUDENT}/one-pager`,
			exact: true,
			component: lazy(() => import('features/one-pager/student')),
		},
		{
			path: `${STUDENT}/one-pager/onboarding`,
			exact: true,
			component: lazy(() => import('features/one-pager/student/onboarding')),
		},
		{
			path: `${STUDENT}/one-pager/details/:id`,
			exact: true,
			component: lazy(() => import('features/one-pager/student/details')),
		},
		{
			path: `${STUDENT}/progress-report`,
			exact: true,
			component: lazy(() => import('features/progress-report')),
		},
		{
			path: `${STUDENT}/tests/:moduleId`,
			exact: true,
			component: lazy(() => import('features/module-test/module-test')),
		},
		{
			path: `${STUDENT}/tests/error/:errorCode`,
			exact: true,
			component: lazy(() => import('features/module-test/module-test-error')),
		},
		{
			path: `${STUDENT}/help`,
			exact: true,
			component: lazy(() => import('features/help')),
		},
		{
			path: `${STUDENT}/onboard`,
			exact: true,
			component: lazy(() => import('features/onboarding')),
		},
		{
			path: `${STUDENT}/startup-lab-events`,
			exact: true,
			component: lazy(() => import('features/startup-lab-event')),
		},
		{
			path: `${STUDENT}/startup-lab-events/:startupLabEventId`,
			exact: true,
			component: lazy(() => import('features/startup-lab-event-details')),
		},
		{
			component: () => <Redirect to="/errors/error-404" />,
		},
	],
};

export default studentRoutes;
