/* eslint-disable no-template-curly-in-string */
import auth from 'features/auth/auth.i18n-hu';
import corporateProblems from 'features/corporate-problems/corporate-problems.i18n';
import dashboard from 'features/dashboard/dashboard.i18n-hu';
import dataExport from 'features/data-export/data-export.i18n-hu';
import lesson from 'features/lesson/lesson.i18n-hu';
import mentor from 'features/mentor/mentor.i18n-hu';
import moduleTest from 'features/module-test/module-test.i18n-hu';
import news from 'features/news/news.i18n-hu';
import onboarding from 'features/onboarding/onboarding.i18n';
import onePager from 'features/one-pager/one-pager.i18n-hu';
import personalityTest from 'features/personality-test/personality-test.i18n-hu';
import progressReportList from 'features/progress-report-list/progress-report-list.i18n';
import progressReport from 'features/progress-report/progress-report.i18n-hu';
import progress from 'features/progress/progress.i18n-hu';
import memberFinder from 'features/project-member-finder/findMembers.i18n-hu';
import project from 'features/project/project.i18n-hu';
import pushNotification from 'features/push-notification/pushNotification.i18n-hu';
import registrationConfirmation from 'features/registration-confirmation/registrationConfirmation.i18n-hu';
import settings from 'features/settings/settings.i18n-hu';
import startupLabEvents from 'features/startup-lab-event/startupLabEvents.i18n-hu';
import student from 'features/student/student.i18n-hu';
import formFields from '../../components/form-fields/form-fields.i18n-hu';
import common from './common.i18n-hu';
import error from './error.i18n-hu';
import HSUPTopics from './hsup-topics.i18n-hu';
import navigation from './navigation.i18n-hu';
import validation from './validation.i18n-hu';

export default {
	translation: {
		navigation,
		common,
		validation,
		error,
		dashboard,
		auth,
		personalityTest,
		moduleTest,
		lesson,
		progress,
		settings,
		onePager,
		project,
		mentor,
		student,
		progressReport,
		progressReportList,
		news,
		startupLabEvents,
		pushNotification,
		corporateProblems,
		HSUPTopics,
		onboarding,
		formFields,
		memberFinder,
		registrationConfirmation,
		dataExport,
	},
};
