import palette from '../palette';

export default {
	root: {
		border: `1px solid #DDE7E7`,
		backgroundColor: 'white',
		borderRadius: 5,
		'&:hover': {
			backgroundColor: 'white',
			border: `2px solid ${palette.primary.dark}`,
		},
		'&$focused': {
			borderColor: palette.primary.main,
			backgroundColor: 'white',
		},
		'&$error': {
			borderColor: palette.error.main,
			backgroundColor: 'white',
		},
	},
	input: {
		paddingTop: '17.5px',
		paddingBottom: '17.5px',
		'&::placeholder': {
			opacity: 0.7,
			color: palette.text.secondary,
		},
	},
};
