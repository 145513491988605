import { URL_PREFIXES } from 'app/constants';
import { MentorLayout } from 'app/layouts/User';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const { MENTOR } = URL_PREFIXES;

const mentorRoutes = {
	path: MENTOR,
	component: MentorLayout,
	routes: [
		{
			path: `${MENTOR}`,
			exact: true,
			component: lazy(() => import('features/project/find-projects')),
		},
		{
			path: `${MENTOR}/news`,
			exact: true,
			component: lazy(() => import('features/news')),
		},
		{
			path: `${MENTOR}/modules`,
			exact: true,
			component: lazy(() => import('features/progress/progress')),
		},
		{
			path: `${MENTOR}/lessons/:id`,
			exact: true,
			component: lazy(() => import('features/lesson/lesson')),
		},
		{
			path: `${MENTOR}/news`,
			exact: true,
			component: lazy(() => import('features/news')),
		},
		{
			path: `${MENTOR}/project/find-projects`,
			exact: true,
			component: lazy(() => import('features/project/find-projects')),
		},
		{
			path: `${MENTOR}/project/project-applications`,
			exact: true,
			component: lazy(() =>
				import('features/mentor/manage-mentor-applications/manage-mentor-applications')
			),
		},
		{
			path: `${MENTOR}/project/project-detail/:projectId`,
			exact: true,
			component: lazy(() => import('features/project/project-detail')),
		},
		{
			path: `${MENTOR}/project/mentor-interests`,
			exact: true,
			component: lazy(() => import('features/project/mentor-interests')),
		},
		{
			path: `${MENTOR}/mentored-projects/`,
			exact: true,
			component: lazy(() => import('features/project/mentored-projects/mentored-projects')),
		},
		{
			path: `${MENTOR}/mentors/find-mentors`,
			exact: true,
			component: lazy(() => import('features/mentor/find-mentors/find-mentors')),
		},
		{
			path: `${MENTOR}/settings`,
			exact: true,
			component: lazy(() => import('features/settings')),
		},
		{
			path: `${MENTOR}/help`,
			exact: true,
			component: lazy(() => import('features/help')),
		},
		{
			path: `${MENTOR}/startup-lab-events`,
			exact: true,
			component: lazy(() => import('features/startup-lab-event')),
		},
		{
			path: `${MENTOR}/startup-lab-events/:startupLabEventId`,
			exact: true,
			component: lazy(() => import('features/startup-lab-event-details')),
		},
		{
			component: () => <Redirect to="/errors/error-404" />,
		},
	],
};

export default mentorRoutes;
