import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import i18n from 'i18next';
import { errorToast, genericRefreshToast } from '../../toast';
import { mapProjectToListItem } from '../project/find-project.slice';

export const getMentorApplications = createAsyncThunk('manage/getMentorApplications', async () => {
	try {
		const response = await axiosService.instance.get('/mentor-application/manage/');
		return response.data;
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const acceptMentorApplication = createAsyncThunk(
	'manage/acceptMentorApplication',
	async ({ applicationId, setLoading }) => {
		try {
			const response = await axiosService.instance.post(
				`/mentor-application/manage/accept/${applicationId}`
			);
			return response.data;
		} catch (error) {
			if (error.response?.data?.message?.includes('Project already has a mentor') > -1) {
				errorToast(i18n.t('mentor.applyMentor.alreadyHasMentor'));
			} else {
				genericRefreshToast();
			}
			throw error;
		} finally {
			setLoading(false);
		}
	}
);

export const rejectMentorApplication = createAsyncThunk(
	'manage/rejectMentorApplication',
	async ({ applicationId, reason, setLoading }) => {
		try {
			const response = await axiosService.instance.post(
				`/mentor-application/manage/decline/${applicationId}`,
				{ reason }
			);
			return response.data;
		} catch (error) {
			genericRefreshToast();
			throw error;
		} finally {
			setLoading(false);
		}
	}
);

export const manageMentorApplicationsSlice = createSlice({
	name: 'manageMentorApplications',
	initialState: {
		applications: null,
		remainingMentorableProjects: null,
	},
	extraReducers: {
		[getMentorApplications.fulfilled]: (state, action) => {
			state.applications = action.payload.applications;
			state.remainingMentorableProjects = action.payload.remainingMentorableProjects;
		},
		[acceptMentorApplication.fulfilled]: (state, action) => {
			state.applications = state.applications.filter(
				(appl) => action.payload._id !== appl._id
			);
			state.remainingMentorableProjects -= 1;
		},
		[rejectMentorApplication.fulfilled]: (state, action) => {
			state.applications = state.applications.filter(
				(appl) => action.payload._id !== appl._id
			);
		},
	},
});

export const selectRemainingMentorableProjects = (state) =>
	state.manageMentorApplications.remainingMentorableProjects;
export const selectManageMentorApplications = (state) =>
	state.manageMentorApplications.applications;
export const selectMentoredProjects = (state) =>
	state.manageMentorApplications.applications
		?.filter((appl) => appl.status === 'ACCEPTED')
		?.map((appl) => mapProjectToListItem(appl._project));

export const selectMentorApplicationForProject = (projectId) => (state) => {
	const {
		manageMentorApplications: { applications },
	} = state;

	if (applications === null) {
		return null;
	}

	return applications.find((application) => application._project._id === projectId);
};

export default manageMentorApplicationsSlice.reducer;
