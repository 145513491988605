import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import i18n from 'i18next';
import { genericRefreshToast, successToast } from '../../toast';
import { getUser } from '../auth.slice';
import { findMentorInterest } from './thunks';

function createQueryString(filtersValues, name) {
	const arrayOrEmpty = filtersValues || [];

	const queryString = arrayOrEmpty.length > 0 ? `&${name}=${arrayOrEmpty.join(',')}` : '';
	return queryString;
}

const initialState = {
	isPending: false,
	findMentorInterestIsPending: false,
	projects: {},
	interests: null,
	findResult: null,
	allCount: 0,
	pagination: {
		totalCount: null,
		pageCount: null,
		pageNumber: 1,
	},
	filters: {
		search: '',
		focuses: null,
		universities: null,
	},
	// last fetched project detail
	// project application uses last visited project
	currentProjectId: null,
	applications: null,
};

export const findProjects = createAsyncThunk('find-project/all', async (params, thunkApi) => {
	const { getState, dispatch } = thunkApi;
	const { pageNumber } = params;

	const state = getState().findProject;
	const filters = params?.filters ?? state.filters;

	// prevent multiple search requests
	if (state.isPending) {
		return null;
	}

	// if action triggered by filter update, save it in store
	// on page change, filters are sent from store
	if (params?.filters) {
		dispatch(updateCachedFilters(params?.filters));
	}

	// block further searches until action finishes
	dispatch(startPending());

	try {
		const search = filters?.search ?? null;
		const universities = filters?.universities ?? null;
		const focuses = filters?.focuses ?? null;

		const pageNumberQuery = `pageNumber=${pageNumber ?? state.pagination.pageNumber}`;
		const searchQuery = search ? `&search=${search}` : '';
		const universitiesQuery = createQueryString(universities, 'universities');
		const focusesQuery = createQueryString(focuses, 'focuses');

		const queryString = `?${pageNumberQuery}${searchQuery}${universitiesQuery}${focusesQuery}`;

		const response = await axiosService.instance.get(`/find-project/all${queryString}`);

		const { findResult, projects, pagination, allCount } = response.data;

		return {
			findResult,
			projects,
			pagination,
			allCount,
		};
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const findApplications = createAsyncThunk('find-project/applications', async () => {
	try {
		const response = await axiosService.instance.get('/find-project/applications');
		const applications = response.data;
		return { applications };
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const fetchProjectDetail = createAsyncThunk('find-project/detail', async (projectId) => {
	try {
		const response = await axiosService.instance.get(`/view-project/${projectId}`);
		const projectDetail = response.data;
		return projectDetail;
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const applyProject = createAsyncThunk('find-project/apply', async (params, thunkApi) => {
	const { getState } = thunkApi;
	const state = getState().findProject;
	const projectId = state.currentProjectId;
	const { formData, history } = params;

	try {
		const response = await axiosService.instance.post(
			`/find-project/apply/${projectId}`,
			formData
		);
		const projectApplication = response.data;
		successToast(i18n.t('project.applyProject.applySuccessMessage'));
		return projectApplication;
	} catch (error) {
		genericRefreshToast();
		throw error;
	} finally {
		history.push('/student/project/find-projects');
	}
});

export const revokeApplication = createAsyncThunk('find-project/revoke', async (params) => {
	const { applicationId, setIsLoading } = params;

	try {
		const response = await axiosService.instance.post(`/find-project/revoke/${applicationId}`);
		const deletedApplicationId = response.data;

		successToast(i18n.t('project.applyProject.revokeSuccessMessage'));

		return { applicationId: deletedApplicationId };
	} catch (error) {
		genericRefreshToast();
		throw error;
	} finally {
		setIsLoading(false);
	}
});

export const goToFindProjectPage = createAsyncThunk(
	'find-project/goToFindProjectPage',
	async (params, thunkApi) => {
		const { history } = params;
		const { dispatch, getState } = thunkApi;

		const state = getState();
		const user = getUser(state);
		const role = user.role.toLowerCase();

		dispatch(clearCachedFilters());
		history.push(`/${role}/project/find-projects`);
	}
);

export const findProjectSlice = createSlice({
	name: 'findProject',
	initialState,
	reducers: {
		startPending(state) {
			state.isPending = true;
		},
		startFindMentorInterestsPending(state) {
			state.findMentorInterestsPending = true;
		},
		updateCachedFilters(state, action) {
			state.filters = action.payload;
		},
		clearCachedFilters(state) {
			state.filters = null;
		},
	},

	extraReducers: {
		[fetchProjectDetail.fulfilled]: (state, action) => {
			const project = action.payload;
			const projectId = project._id;
			state.projects[projectId] = project;
			state.currentProjectId = projectId;
		},
		[findProjects.fulfilled]: (state, action) => {
			// cancelled request
			if (action.payload === null) {
				return;
			}

			state.isPending = false;

			const { findResult, projects, pagination, allCount } = action.payload;
			state.pagination = pagination;
			state.allCount = allCount;
			state.findResult = findResult;
			projects.forEach((project) => {
				const currentProject = state.projects[project._id];
				if (!currentProject) {
					state.projects[project._id] = project;
				}
			});
		},
		[findApplications.fulfilled]: (state, action) => {
			state.applications = action.payload.applications;
		},
		[findMentorInterest.pending]: (state) => {
			state.findMentorInterestIsPending = true;
		},
		[findMentorInterest.fulfilled]: (state, action) => {
			if (action.payload?.interests) {
				state.interests = action.payload.interests;
				state.findMentorInterestIsPending = false;
			}
		},
		[findMentorInterest.rejected]: (state) => {
			state.findMentorInterestIsPending = false;
		},
		[revokeApplication.fulfilled]: (state, action) => {
			const { applicationId } = action.payload;
			state.applications = state.applications.filter(
				(application) => application._id !== applicationId
			);
		},
	},
});

export const {
	startPending,
	updateCachedFilters,
	clearCachedFilters,
	startFindMentorInterestsPending,
} = findProjectSlice.actions;

export const selectProjectDict = (state) => state.findProject.projects;
export const selectFindProjectIdList = (state) => state.findProject.findResult;
export const selectPaginationInfo = (state) => state.findProject.pagination;

export const selectFindProjectResultSize = (state) =>
	state.findProject.pagination.totalCount ?? null;

export const selectFindProjectAllCount = (state) => state.findProject.allCount;

export const selectFindProjectIsPending = (state) => state.findProject.isPending;

export const selectProjectDetail = (projectId) => (state) =>
	// eslint-disable-next-line react/destructuring-assignment
	state.findProject.projects[projectId] ?? null;

export const selectFindProjectList = createSelector(
	[selectProjectDict, selectFindProjectIdList],
	(projects, projectIds) =>
		projectIds?.map((projectId) => mapProjectToListItem(projects[projectId])) ?? null
);

export default findProjectSlice.reducer;

export function mapProjectToListItem(project) {
	return {
		id: project._id,
		title: project.name,
		lead: project.shortDescription,
		projectOwner: project._owner.fullName,
		remainingSeats: project.remainingSeats,
	};
}

export const selectProjectApplications = (state) =>
	state.findProject.applications?.map(mapProjectApplicationToListItem) ?? null;

function mapProjectApplicationToListItem(projectApplication) {
	return {
		id: projectApplication._id,
		title: projectApplication._project.name,
		lead: projectApplication._project.shortDescription,
		projectOwner: projectApplication._project._owner?.fullName ?? '',
		projectId: projectApplication._project._id,
		status: projectApplication.status,
	};
}

export const selectMentorInterests = (state) => state.findProject.interests;
