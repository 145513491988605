import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from 'app/i18n';
import axiosService from 'app/store/axiosService';
import { RootState } from 'app/store/store';
import { genericRefreshToast, successToast } from 'app/store/toast';
import { findMentorInterest } from './findMentorInterestThunk';

export const sendMentorInterest = createAsyncThunk<
	{ newInterest: unknown },
	void,
	{ state: RootState }
>('mentor-interest/send', async (_, thunkApi) => {
	const { getState, dispatch } = thunkApi;
	const state = getState().findProject;
	const id = state.currentProjectId;

	try {
		const response = await axiosService.instance.post(`/mentor-interest/send-interest`, {
			id,
		});
		const newInterest = response.data;
		successToast(i18n.t('project.mentorInterests.interestSuccessMessage'));
		if (!state.findMentorInterestIsPending) {
			dispatch(findMentorInterest());
		}
		return { newInterest };
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});
