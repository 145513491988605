export default {
	fields: {
		surname: 'Surname',
		firstname: 'Firstname',
		neptun: 'Neptun code',
		username: 'Username',
		email: 'E-mail address',
		password: 'Password',
		university: 'University',
		tnc: {
			beginning: 'I accept the ',
			link: 'privacy policy',
		},
		submit: 'Registration',
		newPassword: 'New password',
		newPasswordVerification: 'New password verification',
		setupPassword: {
			password: 'Password',
			confirmPassword: 'Confirm password',
		},
	},
	buttons: {
		login: 'Log in',
	},
	pages: {
		login: {
			title: 'Log in',
			subtitle: 'Log in to access the HSUP platform',
			noProfile: "Don't have an account yet?",
			signInButton: 'Sign in',
			forgotPassword: 'I forgot my password',
			wrongCredentials: 'Incorrect e-mail or password',
			networkError: 'Network access error. Check your internet connection',
			otherError: 'An error has occurred. Try again later.',
		},
		forgotPassword: {
			title: 'Forgot your password?',
			subtitle: 'Enter your email address and you can easily create a new password',
			successHeader: 'Password reset e-mail sent',
			successDescription:
				'If you have previously registered using the email address you provided and completed the registration process, you will receive an email with further instructions.',
			continue: 'Continue',
			back: 'Back',
			done: 'Done',
		},
		changePassword: {
			title: 'Change password',
			editPassword: 'Change Password',
			successHeader: 'Password changed successfully!',
			successDescription: 'You can now log in with the new password.',
			failHeader: 'Unsuccessful modification!',
			failDescription: 'This link has expired.',
			failedButtonLabel: 'Back to the main page',
		},
		register: {
			title: 'Registration',
			subtitle: 'Register to access the HSUP platform',
			registerAction: 'Register',
			successHeader: 'Successful registration',
			successDescription: 'Welcome to HSUP, you can now log in.',
			failHeader: 'Registration failed!',
			failDescription: 'This link has expired.',
			failedButtonLabel: 'Back to main page',
		},
		verify: {
			title: 'Verify email',
			page: {
				title: 'We’ve sent you a verification mail to your mail address! ✉️',
				subtitle:
					"We have sent a verification email to the email address associated with your account. Please check your inbox, including your spam or junk folder, for the message. Inside the email, you'll find a link or code that you need to use to verify your account. If you don't receive the email within a few minutes, you may request another one or ensure that the email address provided is correct.",
			},
		},
		verifySuccess: {
			title: 'Email verified',
			page: {
				title: 'Email verified successfully',
				subtitle:
					'We’ve verified your e-mail address, so you can use this to login after the registration process.',
				next: 'next',
			},
		},
		registrationConfirm: {
			title: 'Confirming registration',
			page: {
				title: 'Just one more step on our side.. ⏳',
				subtitle: 'Please wait until we verify your login information',
				note: 'We’ll send you an e-mail when we’ve verified your login information. Please be patient, it can take 4-8 days.',
			},
		},
		setupPassword: {
			title: 'Setup password',
			page: {
				title: 'Setup your password',
				subtitle: 'Please select a strong password to ensure the security of your account. Make sure to choose a password that is both secure and memorable.',
				submit: 'Save & Login',
			},
		},
	},
};
