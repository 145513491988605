import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from '../../axiosService';
import { genericRefreshToast } from '../../toast';
import { Project } from '../types/Project';
import { ProjectMember } from '../types/ProjectMember';

export type InvitationWithMember = {
	_id: string;
	_project: string;
	_member: ProjectMember;
};

export type InvitationWithProject = {
	_id: string;
	_member: string;
	_project: Project;
	projectOwnerName: string;
};

type MemberInvitationInitialState = {
	findMemberInvitationsIsLoading: boolean;
	invitationsForProjectOwner: InvitationWithMember[];
	invitationsForProjectMember: InvitationWithProject[] | null;
	pagination: {
		totalCount: number | null;
		pageCount: number | null;
		pageNumber: number;
	};
};

const initialState: MemberInvitationInitialState = {
	findMemberInvitationsIsLoading: false,
	invitationsForProjectOwner: [],
	invitationsForProjectMember: null, // initially null signs if not loaded yet
	pagination: {
		totalCount: null,
		pageCount: null,
		pageNumber: 1,
	},
};

export const findMemberInvitationsForProjectMember = createAsyncThunk(
	'member-invitation/findInvitesForProjectMember',
	async () => {
		try {
			const response = await axiosService.instance.get(`member-invitation/project-member`);

			return response.data;
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const findMemberInvitationsForProjectOwner = createAsyncThunk(
	'member-invitation/findInvitesForProjectOwner',
	async () => {
		try {
			const response = await axiosService.instance.get(`member-invitation/project-owner`);

			return response.data;
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

type InviteProjectMemberParams = {
	projectMemberId: string;
	setIsLoading: (value: boolean) => void;
	onSuccess: () => void;
};

export const inviteProjectMember = createAsyncThunk(
	'member-invitation/inviteProjectMember',
	async (params: InviteProjectMemberParams) => {
		const { projectMemberId, setIsLoading, onSuccess } = params;

		try {
			const response = await axiosService.instance.post(
				`member-invitation/${projectMemberId}`
			);

			onSuccess();

			return response.data;
		} catch (error) {
			setIsLoading(false);
			genericRefreshToast();
			throw error;
		}
	}
);

export const memberInvitationSlice = createSlice({
	name: 'memberInvitationSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(inviteProjectMember.fulfilled, (state, action) => {
			state.invitationsForProjectOwner.push(action.payload);
		});
		builder.addCase(findMemberInvitationsForProjectOwner.fulfilled, (state, action) => {
			state.invitationsForProjectOwner = action.payload;
		});
		builder.addCase(findMemberInvitationsForProjectMember.pending, (state) => {
			state.findMemberInvitationsIsLoading = true;
		});
		builder.addCase(findMemberInvitationsForProjectMember.fulfilled, (state, action) => {
			state.findMemberInvitationsIsLoading = false;
			state.invitationsForProjectMember = action.payload;
		});
	},
});

export const selectInvitationsForProjectOwner = (state) => {
	return state.memberInvitation.invitationsForProjectOwner;
};

export const selectInvitationsForProjectMember = (state) => {
	return state.memberInvitation.invitationsForProjectMember;
};

export const selectFindMemberInvitationsIsLoading = (state) => {
	return state.memberInvitation.findMemberInvitationsIsLoading;
};

export const selectInviteMemberResultSize = (state) =>
	state.memberInvitation.pagination.totalCount ?? null;

export const selectInviteMemberPaginationInfo = (state) => state.memberInvitation.pagination;

export default memberInvitationSlice.reducer;
