export default {
	header: {
		welcome: 'Hi, ',
		zeroModulesCompleted: {
			text: 'Click on the first module to start the course',
			link: '',
		},
		someModulesCompleted: {
			text: 'Some modules still need to be filled in.',
			link: 'Complete them now.',
		},
		allModulesCompleted: {
			text: 'You have completed all modules. Well done!',
			link: '',
		},
		secondSemester:
			'In this semester you have 5 modules waiting for you. You also have your team, mentors and super available opportunities! Good luck!',
		universitySubtitle:
			'Welcome to the Hungarian Startup University Program. Follow the achievements of your university and your students',
		officeSubtitle: 'One who sees all',
	},
	performance: {
		title: 'Your performance',
		suggestedGrade: 'Your proposed grade',
		text: {
			start: 'Your final result',
			end: ", don't forget to send your one-pager to complete the course",
		},
		suggestedText:
			'If you still have time to try, you can improve your grade before the end of the semester',
		grades: {
			5: 'excellent',
			4: 'good',
			3: 'satisfactory',
			2: 'sufficient',
			1: 'insufficient',
		},
		sum: 'Total',
		moduleProgress: {
			completed: 'Completed',
			notCompleted: 'Not completed',
			notAvailable: 'Not available',
		},
		body: {
			start: 'So far you got',
			points: 'points',
			middle: 'by completing',
			end: 'modules',
		},
	},
	progress: {
		title: 'Progress',
		modules: 'Completed modules',
		onePager: 'Submit one-pager',
		progressReport: 'Submit progress report',
		send: 'Submit',
		moreInfo: 'More information',
		chart: {
			completed: 'Completed modules',
			remaining: 'Number of modules',
		},
	},
	points: {
		title: 'Points',
		text: {
			start: 'You collected',
			points: 'points',
			middle: 'so far',
			end: 'completed module tests.',
		},
		subtitle: 'POINTS',
		acquired: 'Acquired',
		available: 'Available',
	},
	mentor: {
		headline: 'Need a mentor?',
		subtitle: 'Do you need some help? We will connect you with your mentor.',
		contact: 'Contact us',
	},
	personality: {
		ceo: { name: 'Chief executive officer', role: 'CEO' },
		cto: { name: 'Chief technology officer', role: 'CTO' },
		pm: { name: 'Project manager', role: 'PM' },
		kreativ: { name: 'Head of design', role: 'Creative' },
	},
	userStatistics: {
		completedExams: 'Tests completed',
		logins: 'Number of logins',
		suggestions: 'Number of suggestions',
	},
	scoresTable: {
		university: 'University',
		students: 'Students',
		student: 'Student',
		score: 'Scores',
		average: 'Average',
	},
	statistics: {
		student: {
			title: 'Student Ranking',
		},
		university: {
			title: 'University ranking table',
		},
		office: {
			title: 'University data - {{semester}}. semester',
			all: 'All universities',
		},
		progress: {
			title: 'Number of test completions',
			text: 'module test',
		},
		personality: {
			title: 'Personality test results',
		},
		registration: {
			title: 'Registration and project data',
			texts: {
				email: 'Number of students in the current semester',
				project: 'Number of students working in a project team',
				alone: 'Number of students looking for a project team',
			},
		},
		course: {
			title: 'Course data',
			metrics: {
				finish: 'Number of students completing the course',
				score: 'Highest score achieved',
				average: 'University average score',
				feedback: 'Number of proposals received',
			},
			suffix: {
				student: 'student',
				score: 'score',
				count: '',
			},
		},
	},
	mentorInterest: {
		title: 'Do you need a mentor?',
		content: 'We will connect you with your mentor',
		button: 'Mentor list',
	},
	certificate: {
		title: 'Congratulations on completing the semester',
		content: 'Download your certificate and share it with others',
		downloadBtnText: 'Download certificate',
	},
	fillBio: {
		title: 'Complete your profile',
		subtitle:
			'Complete your profile to get the attention of project managers so they can get to know you better',
		button: 'Fill in profile data',
	},
};
