import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from '../axiosService';
import { genericRefreshToast } from '../toast';
import { getUser } from './auth.slice';

const initialState = {
	isPending: false,
	data: null,
	pagination: {
		totalCount: null,
		pageCount: null,
		pageNumber: 1,
	},
	filters: {
		search: '',
		universities: null,
	},
};

export const getRegistrationConfirmations = createAsyncThunk(
	'registrationConfirmations/all',
	async (params = {}, thunkApi) => {
		const { getState, dispatch } = thunkApi;
		const { pageNumber } = params;

		const state = getState();
		const registrationConfirmationsState = state.registrationConfirmation;
		const filters = params.filters ?? registrationConfirmationsState.filters;

		// prevent multiple search requests
		if (registrationConfirmationsState.isPending) {
			return null;
		}

		// if action triggered by filter update, save it in store
		// on page change, filters are sent from store
		if (params.filters) {
			dispatch(updateCachedFilters(params.filters));
		}

		// block further searches until action finishes
		dispatch(startPending());

		try {
			const search = filters?.search ?? null;
			const { universities, status } = filters;
			const user = getUser(state);
			const role = user.role.toLowerCase();

			const requestBody = {
				universities,
				status,
				search,
				pageNumber: pageNumber ?? registrationConfirmationsState.pagination.pageNumber,
			};

			const response = await axiosService.instance.post(
				`/registration-confirmations/all/${role}`,
				requestBody
			);
			const { data, pagination, allCount } = response.data;

			return { data, pagination, allCount };
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const registrationConfirmationsSlice = createSlice({
	name: 'registrationConfirmations',
	initialState,
	reducers: {
		startPending(state) {
			state.isPending = true;
		},
		updateCachedFilters(state, action) {
			state.filters = action.payload;
		},
	},
	extraReducers: {
		[getRegistrationConfirmations.fulfilled]: (state, action) => {
			// cancelled request
			if (action.payload === null) {
				return;
			}

			state.isPending = false;

			const { data, pagination, allCount } = action.payload;
			state.pagination = pagination;
			state.data = data;
			state.allCount = allCount;
		},
	},
});

export const { startPending, updateCachedFilters } = registrationConfirmationsSlice.actions;

export const selectRegistrationConfirmationsList = (state) => state.registrationConfirmation.data;
export const selectRegistrationConfirmationsPaginationInfo = (state) =>
	state.registrationConfirmation.pagination;
export const selectRegistrationConfirmationIsPending = (state) =>
	state.registrationConfirmation.isPending;
export const selectRegistrationConfirmationResultSize = (state) =>
	state.registrationConfirmation.pagination.totalCount;
export const selectRegistrationConfirmationAllCount = (state) =>
	state.registrationConfirmation.allCount;

export default registrationConfirmationsSlice.reducer;
