export default {
	header: {
		title: 'EXAM',
	},
	navigation: {
		next: 'Next',
		back: 'Back',
		submit: 'Submit',
	},
	errors: {
		semesterEnded: 'The semester has ended',
		semesterEndedDescription:
			'You can review your results and proposed grade on the dashboard.',
		moduleInactive: 'You have not yet unlocked the module for this exam.',
		moduleInactiveDescription:
			"However, there's no need to worry, as you can unlock it easily by completing the test in the preceding modules. Good luck!",
		outOfAttempts: 'You have exhausted your allowed attempts.',
		outOfAttemptsDescription:
			'The points you have earned will still contribute to your overall grade. There is no need to worry; we have unlocked the next module for you. Please begin now.',
		noExamForModule: 'No exam for module',
		noExamForModuleDescription: 'There is no exam for this module.',
		serviceUnavailable: "Module tests are unavailable",
		serviceUnavailableDescription: "Module tests are currently unavailable. Please try again later. If the issue persists please contact HSUP's customer support team at info.hsup@niu.hu.",
		initFailed: "An error occured :(",
		initFailedDescription: "An unexpected error occured while initializing the module test. Please contact HSUP's customer support team at info.hsup@niu.hu and provide the following reference:",
		resultSubmissionFailed: "An error occurred :(",
		resultSubmissionFailedDescription: "An unexpected error occurred while storing the test results. Please contact HSUP's customer support team at info.hsup@niu.hu and provide the following reference:",
		genericError: "An error occurred :(",
		genericErrorDescription: "An unexpected error has occurred. Please contact HSUP's customer support team at info.hsup@niu.hu."
	}
};
