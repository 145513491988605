/* eslint-disable no-template-curly-in-string */
export default {
	email: 'Incorrect email address',
	required: 'Required field',
	min: 'Must be at least ${min} characters',
	max: 'Maximum ${max} characters',
	minLanguage: 'At least ${min} language',
	matchPassword: 'The two passwords do not match',
	matchIdentityNumberFormat: 'Format: AA123456',
	notSameLanguage: 'The two languages cannot be the same',
	positive: 'The specified number is not positive',
	notBeforeToday: 'Can\'t be earlier than today',
	notBeforeRef: 'Cannot be earlier than the deadline for submission/giving offer',
	notType: 'Incorrect format',
	matches: 'At least 1 number',
	tooLargeFile: 'File is too large, maximum size: ',
	array: {
		min: 'At least ${min} must be selected'},
	limit: {
		uploadMax: 'Number of documents to upload: {{count}}',
		uploadMaxError: 'Up to {{count}} files can be uploaded',
	},
	url: 'Invalid link! The link must start with "https://".',
};
