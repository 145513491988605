import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';
import axiosService from '../axiosService';

export const initTest = createAsyncThunk(
	'module-test/init',
	async ({module}, { rejectWithValue }) => {
		try {
			const { data: url } = await axiosService.instance.post(
				'/init-module-test',
				{
					moduleId: module,
					platform: 'WEB'
				}
			);

			return url;
		} catch (error) {
			const errorResponse = error.response?.data;
			const statusCode = errorResponse?.statusCode;
			const requestId = errorResponse?.message;
			if (statusCode === 503) {
				return rejectWithValue({
					errorTitle: i18n.t('moduleTest.errors.serviceUnavailable'),
					errorDescription: i18n.t('moduleTest.errors.serviceUnavailableDescription')
				});
			}
			if (statusCode === 409 && requestId) {
				return rejectWithValue({
					errorTitle: i18n.t('moduleTest.errors.initFailed'),
					errorDescription: `${i18n.t('moduleTest.errors.initFailedDescription')} ${requestId}`
				});
			}

			return rejectWithValue({
				errorTitle: i18n.t('moduleTest.errors.genericError'),
				errorDescription: i18n.t('moduleTest.errors.genericErrorDescription')
			});
		}
	});

export const moduleTestsSlice = createSlice({
	name: 'moduleTests',
	initialState: {
		redirectUrl: null,
		error: null,
		loading: false,
	},
	reducers: {
		resetModuleTestInit: (state) => {
			state.loading = false;
			state.redirectUrl = null;
			state.error = null;
		}
	},
	extraReducers: {
		[initTest.pending]: (state) => {
			state.loading = true;
			state.redirectUrl = null;
			state.error = null;
		},
		[initTest.fulfilled]: (state, action) => {
			state.loading = false;
			state.redirectUrl = action.payload;
			state.error = null;
		},
		[initTest.rejected]: (state, action) => {
			state.loading = false;
			state.redirectUrl = null;
			state.error = action.payload;
		}
	}
});

export const { resetModuleTestInit } = moduleTestsSlice.actions;
export const getModuleTestLoading = (state) => state.moduleTests.loading;
export const getModuleTestRedirectUrl = (state) => state.moduleTests.redirectUrl;
export const getModuleTestError = (state) => state.moduleTests.error;

export default moduleTestsSlice.reducer;
