import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { genericRefreshToast } from 'app/store/toast';

export const listOnePagers = createAsyncThunk('list-one-pagers', async (params = {}, thunkApi) => {
	const { getState, dispatch } = thunkApi;
	const { pageNumber } = params;

	const state = getState();
	const onePagerState = state.onePagerList;
	const filters = params.filters ?? onePagerState.filters;

	if (onePagerListSlice.isPending) {
		return null;
	}

	if (params.filters) {
		dispatch(updateCachedFilters(params.filters));
	}

	// block further searches until action finishes
	dispatch(startPending());

	try {
		const search = filters?.search ?? null;
		const universities = filters ? filters.universities : [];
		const corporateProblemIds = filters ? filters.corporateProblemIds : [];
		const isUploaded = filters ? filters.isUploaded : [];

		const requestBody = {
			search,
			universities,
			corporateProblemIds,
			isUploaded,
			pageNumber: pageNumber ?? onePagerState.pagination.pageNumber,
		};

		const response = await axiosService.instance.post(`/one-pager/fetchPage`, requestBody);

		return response.data;
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const onePagerListSlice = createSlice({
	name: 'onePagerList',
	initialState: {
		isPending: false,
		onePagers: null,
		onePagersUploadCount: null,
		studentCount: null,
		pagination: {
			totalCount: null,
			pageCount: null,
			pageNumber: 1,
		},
		filters: {
			search: '',
			universities: null,
			corporateProblemIds: null,
		},
		allCount: null,
	},
	reducers: {
		startPending(state) {
			state.isPending = true;
		},
		updateCachedFilters(state, action) {
			state.filters = action.payload;
		},
	},
	extraReducers: {
		[listOnePagers.pending]: (state, action) => {
			if (action?.meta?.arg?.initialLoad) {
				state.isPending = true;
			}
		},
		[listOnePagers.fulfilled]: (state, action) => {
			const { onePagers, onePagersUploadCount, studentCount, pagination, allCount } =
				action.payload;

			state.isPending = false;
			state.pagination = pagination;
			state.onePagers = onePagers;
			state.onePagersUploadCount = onePagersUploadCount;
			state.studentCount = studentCount;
			state.allCount = allCount;
		},
	},
});

export const { startPending, updateCachedFilters } = onePagerListSlice.actions;

export const selectOnePagerListPaginationInfo = (state) => state.onePagerList.pagination;
export const selectOnePagerListResultSize = (state) =>
	state.onePagerList.pagination.totalCount ?? null;
export const selectOnePagerListIsPending = (state) => state.onePagerList.isPending;
export const selectOnePagerList = (state) => state.onePagerList.onePagers;
export const selectOnePagersUploadRate = (state) => ({
	studentCount: state.onePagerList.studentCount,
	uploadedCount: state.onePagerList.onePagersUploadCount,
});
export const selectOnePagerFilteredInfo = (state) => ({
	filtered: state.onePagerList.pagination?.totalCount,
	all: state.onePagerList.allCount,
});

export default onePagerListSlice.reducer;
