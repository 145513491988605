export default {
	header: {
		title: 'Progress report',
		subtitle:
			'Here you can find the progress reports uploaded during the semester. The status is continuously updated as students upload their team\'s progress report. The report is uploaded by the project owner to their own course supervisor.',
	},
	subheading: {
		start: 'Until now',
		end: 'teams have uploaded their progress report.',
	},
	content: {
		projectName: 'Project name',
		projectOwner: 'Project owner',
		uploadDate: 'Upload date',
		university: 'University',
	},
	uploadStat: {
		team: 'team',
		description: 'uploaded progress report',
	},
	export: 'Export (.zip)',
};
