import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from '../axiosService';
import { genericRefreshToast } from '../toast';

export const fetchPushNotifications = createAsyncThunk('pushNotifications/fetch', async () => {
	try {
		const response = await axiosService.instance.get('/push-notification');
		return response.data;
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const sendPushNotification = createAsyncThunk(
	'pushNotification/send',
	async ({ values, onSuccess }) => {
		try {
			const response = await axiosService.instance.post('push-notification/send', values);
			onSuccess();
			return response.data;
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const pushNotificationSlice = createSlice({
	name: 'pushNotification',
	initialState: {
		isLoading: false,
		isSending: false,
		data: [],
	},
	extraReducers: {
		[fetchPushNotifications.pending]: (state) => {
			state.isLoading = true;
		},
		[fetchPushNotifications.fulfilled]: (state, action) => {
			state.data = action.payload;
			state.isLoading = false;
		},
		[sendPushNotification.pending]: (state) => {
			state.isSending = true;
		},
		[sendPushNotification.fulfilled]: (state, action) => {
			state.data = [action.payload, ...state.data];
			state.isSending = false;
		},
		[sendPushNotification.rejected]: (state) => {
			state.isSending = false;
		},
	},
});

export default pushNotificationSlice.reducer;

export const selectPushNotifications = ({ pushNotification: { data, isLoading, isSending } }) => ({
	data,
	isLoading,
	isSending,
});
