/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { genericRefreshToast } from '../../toast';
import { updateMentorApplications } from './mentorApplication.slice';

const initialState = {
	isPending: false,
	mentors: {},
	findResult: null,
	pagination: {
		totalCount: null,
		pageCount: null,
		pageNumber: 1,
	},
	filters: {
		search: '',
		specializations: null,
		focuses: null,
	},
};

export const findMentors = createAsyncThunk('find-mentor/all', async (params = {}, thunkApi) => {
	const { getState, dispatch } = thunkApi;
	const { pageNumber } = params;

	const state = getState().findMentor;
	const filters = params?.filters ?? state.filters;

	// prevent multiple search requests
	if (state.isPending) {
		return null;
	}

	// if action triggered by filter update, save it in store
	// on page change, filters are sent from store
	if (params?.filters) {
		dispatch(updateCachedFilters(params?.filters));
	}

	// block further searches until action finishes
	dispatch(startPending());

	try {
		const search = filters?.search ?? null;
		const { specializations } = filters;
		const { focuses } = filters;

		const requestBody = {
			specializations,
			focuses,
			search,
			pageNumber: pageNumber ?? state.pagination.pageNumber,
		};

		const response = await axiosService.instance.post(`/find-mentor/all`, requestBody);

		const { findResult, mentors, allCount, pagination, applications } = response.data;
		dispatch(updateMentorApplications(applications));

		const appliedMentorIds = applications.map((application) => application._mentor._id);

		// do not show pending applications in find result
		// block applying twice for a project
		const findResultWithoutAppliedMentors = findResult.filter(
			(mentorId) => !appliedMentorIds.includes(mentorId)
		);

		return {
			findResult: findResultWithoutAppliedMentors,
			mentors,
			allCount,
			pagination,
		};
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const findMentorSlice = createSlice({
	name: 'findMentor',
	initialState,
	reducers: {
		startPending(state) {
			state.isPending = true;
		},
		updateCachedFilters(state, action) {
			state.filters = action.payload;
		},
	},

	extraReducers: {
		[findMentors.fulfilled]: (state, action) => {
			// cancelled request
			if (action.payload === null) {
				return;
			}

			state.isPending = false;

			const { findResult, mentors, allCount, pagination } = action.payload;
			state.allCount = allCount;
			state.pagination = pagination;
			state.findResult = findResult;
			mentors.forEach((mentor) => {
				state.mentors[mentor._id] = mentor;
			});
		},
	},
});

export const { startPending, updateCachedFilters } = findMentorSlice.actions;
export const selectMentorsDict = (state) => state.findMentor.mentors;
export const selectFindMentorIdList = (state) => state.findMentor.findResult;
export const selectFindMentorPaginationInfo = (state) => state.findMentor.pagination;
export const selectFindMentorResultSize = (state) => state.findMentor.pagination.totalCount ?? null;
export const selectFindMentorExportInfo = (state) => ({
	filteredCount: state.findMentor.pagination.totalCount ?? null,
	allCount: state.findMentor.allCount,
});
export const selectFindMentorIsPending = (state) => state.findMentor.isPending;

export const selectFindMentorList = createSelector(
	[selectMentorsDict, selectFindMentorIdList],
	(mentors, projectIds) => projectIds?.map((projectId) => mentors[projectId]) ?? null
);

export default findMentorSlice.reducer;
