import { LinearProgress, makeStyles } from '@material-ui/core';
import { LAYOUT_TYPES } from 'app/constants';
import { useRouterGuard } from 'app/guards/useRouterGuard';
import { loaderSelector } from 'app/store/slices/loader.slice';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';

const useStyles = makeStyles((theme) => ({
	content: {
		backgroundColor: theme.palette.background.default,
	},
}));

export default function OnboardingLayout({ route }) {
	const classes = useStyles();
	const { isLoading } = useSelector(loaderSelector);

	useRouterGuard(LAYOUT_TYPES.ONBOARDING);

	return (
		<main className={classes.content}>
			<Suspense fallback={<LinearProgress />}>
				{isLoading && <LinearProgress />}
				{renderRoutes(route.routes)}
			</Suspense>
		</main>
	);
}
