export default {
	onboardButtons: {
		prev: 'Back',
		next: 'Next',
	},
	onboardingTitle: 'Onboarding',
	onboardingSubTitle:
		'Please review the essential information about the semester before accessing the dashboard ',
	onboarded: {
		firstSemester: {
			title: 'Onboard',
			submit: 'Personality test',
		},
		secondSemester: {
			title: 'Onboard',
			submit: 'Finish',
			owner: 'For project owners',
			member: 'For HSUP students',
		},
	},
	welcome: {
		header: {
			title: 'Hi,',
			subtitle: 'Take the personality test before starting your learning journey.',
		},
		subheader: {
			title: 'What is your personality type?',
			subtitle: "Let's find out together",
			button: 'Start test',
			text: "The role you play within a startup depends on various factors. In HSUP, we have identified four founder profiles, each offering personalized bonus learning materials. Click on the cards below to learn more about these profiles. When you're ready, click the Start test button.",
		},
	},
	detail: {
		headline: {
			title: 'What personality traits characterise a',
			subtitle: "Let's see",
		},
		summary: {
			ceo: {
				title: 's as explorers',
			},
			cto: {
				title: 's as analysts',
			},
			pm: {
				title: 's as protectors',
			},
			kreativ: {
				title: 's as diplomats',
			},
			testButton: 'Start test',
			backButton: 'Back',
		},
	},
	test: {
		header: {
			title: 'We will discover what characterises you best.',
			subtitle: 'Personality test',
		},
		subheader: {
			title: 'What is your personality type?',
			subtitle: 'Answer truthfully for precise results.',
		},
		form: {
			buttons: {
				next: 'Next',
				prev: 'Back',
				submit: 'Submit test',
			},
			question: 'To what extent do you agree with the following statements?',
			text: {
				agree: 'I agree',
				disagree: 'I disagree',
			},
		},
		help: {
			first: 'It takes about 10 minutes to complete the test.',
			second: 'Answer the questions honestly!',
			third: 'Try to answer the questions NOT in a neutral way!',
		},
	},
	success: {
		result: 'Result',
		chooseAnother: 'Choose personality type',
		saveAndClose: 'Save & close',
		headline: {
			titleStart: 'Among the four personality types the',
			titleEnd: 'fits you best.',
			titleMulti:
				'Based on our algorithm, it appears that more than one personality type may be suitable for you. Please select the one that fits you the best.',
			subtitle:
				'Throughout the course, you will receive bonus materials tailored to match this personality type.',
		},
		summary: {
			title: 'about',
			submit: 'Continue as',
			ceo: {
				title: 'about',
				text: 'You are an energetic, inspiring leader. You propel the team forward and pave the way. You negotiate with investors, collect business cards at networking events, and tirelessly pursue your vision. While guidelines are useful, you thrive on exploring new directions that could drive your startup to become the next big thing.',
				long: 'You are a proactive manager who values forward-thinking and creativity to maximize team potential. As an inspiring leader, you embrace challenges and are adept at setting clear team goals and communicating them effectively. Maintaining control is crucial for you, allowing you to approach projects with enthusiasm. However, staying focused is key to avoiding distractions that could hinder team progress. Your strength lies in networking events where your passion and dedication shine through as you discuss ideas. Balancing freedom with structured work schedules is essential for achieving rapid and efficient progress. Building a startup is a formidable challenge, requiring thorough preparation for the obstacles ahead. Your flexible approach to rules fuels innovative solutions in product, marketing, branding, and sales, yet maintaining consistent motivation during less dynamic phases is essential.',
			},
			cto: {
				title: 'about',
				text: 'You approach challenges with pragmatic, well-thought-out, and highly functional systems. This rare skill is invaluable when making strategic decisions in technology. As a forward-thinking team member, you consistently stay ahead of the product development curve. Your calm demeanor and elegant solutions inspire confidence in others, making you a go-to resource for resolving complex issues.',
				long: "As a technology leader, you strive for perfection, rejecting sloppy work with careful consideration of all factors. You insist on examining diverse perspectives before moving forward, recognizing that the best solution may emerge from unexpected sources. Balancing technical expertise with team dynamics, you maintain a firm grip on operations. You are known as a tough yet fair leader who delivers praise and criticism constructively to foster improvement. However, maintaining order amidst team diversity requires patience and calm persuasion to articulate your vision effectively. Embracing both emotional intelligence and technical acumen is essential. Avoid becoming impatient or judgmental when others are still exploring ideas you've already resolved. Your swift, confident, and often accurate decisions offer significant advantages to your startup. Coupled with hard work, openness, and willingness to compromise, these qualities pave the way for your idea's success with your team.",
			},
			pm: {
				title: 'about in brief',
				text: 'Startups can move swiftly, but without a highly skilled project manager, the pace may falter. Your exceptional organizational abilities are invaluable to the startup, ensuring efficient progress and adaptation throughout the process.',
				long: "Order is the essence of everything. Alongside the technology manager, you are the linchpin responsible for the system—focusing not just on technology but on workflow. You are the backbone of the startup, connecting invisible processes with clear communication and high organization. Your thoughtful, calm, and pragmatic approach prevents many team headaches. A touch of transparency here and process optimization there ensures effective team communication and streamlined tasks. However, conflicts are inevitable, as rigid adherence to procedures often clashes with real-world challenges—the manager understands this best. Approach situations calmly, weighing what's best for your startup. Another internal challenge is your difficulty in declining additional tasks. Unlike the CEO, who faces this issue for different reasons, your conscientious nature often leads to scattered focus and overwhelms the established system. As a dedicated startup professional, however, seeking feedback and focusing on long-term goals will help maintain clarity and drive towards the success of your startup.",
			},
			kreativ: {
				title: 'about',
				text: 'Your bold, new, and creative ideas are the essence of the product. Your boundless creativity shines through in the beauty, usability, and numerous innovations of your startup solutions. Your original insights and playful approach inspire others, boosting team morale and ensuring steady progress.',
				long: "Art for art's sake. Whether on paper, canvas, or sheet music, creativity and expression define your world. Depending on your strongest talent, you excel as the team's top copywriter, designer, or creator of flawless user experiences. Your original ideas inject vibrant color into previously monochromatic interfaces, making your product truly unique. As a creative and optimistic team member, you delight others with innovative, sometimes subtle, solutions by approaching problems from fresh perspectives. Routine is not your forte, which can present challenges later on. You must learn that artistic expression needs purpose, channeling creativity in ways that drive startup success. Prepare early for the inevitable trials, ready to make business decisions and compromises that balance artistry with business advancement. As a more introspective individual, the whirlwind of startup dynamics may initially evade you, yet it offers the opportunity to observe and uncover overlooked details. Your insights and ideas breathe life and playfulness into the startup's product, elevating it beyond the ordinary.",
			},
		},
		notSure: {
			title: "Do you feel the outcome doesn't quite match your personality?",
			subtitle: 'Select the personality type you are most comfortable with.',
		},
	},
};
