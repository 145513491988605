import { colors } from '@material-ui/core';

const white = '#FFFFFF';

export default {
	primary: {
		contrastText: white,
		main: '#1EBEDC',
		mainDarker: '#1CB3CE', // XD
		light: '#DFF6FB',
		dark: '#283737',
	},
	secondary: {
		contrastText: white,
		main: '#283737',
		light: '#F9FBFB',
		otherLight: '#F0F4F4', // XD
	},
	ternary: {
		dark: '#D69D00',
		main: '#FAB900',
	},
	error: {
		contrastText: white,
		dark: colors.red[900],
		main: colors.red[600],
		light: colors.red[400],
	},
	text: {
		primary: '#283737',
		secondary: '#567676',
		muted: '#A1BBBB',
		link: '#1CB3CE',
	},
	background: {
		default: white,
		paper: white,
		paperDark: '#ECEFF1',
		gradient: 'linear-gradient(180deg, #F5F6F9 0%, rgba(245, 246, 249, 0.0001) 38.54%)',
	},
	divider: '#F5F6F9',
};
