export default {
	firstSemester: {
		semester: 'Semester I',
		topics: {
			1: {
				title: 'Welcome to the Hungarian Startup University Program',
				alt: 'blogging',
				text: 'Please read the following brief introduction carefully to familiarize yourself with the most important facts for the semester.',
			},
			2: {
				title: 'The curriculum',
				alt: 'relax_sleep',
				text: 'The semester comprises seven modules designed to introduce you to the world of startups. These modules include bonus chapters which, although not mandatory, are highly recommended. Through these, you can learn business practices to accelerate the growth of your startup.',
			},
			3: {
				title: 'Tests',
				alt: 'support',
				text: 'HSUP is not a traditional university course, so there is no need to fear tests. Take your time to go through the material, and you will not encounter any surprises. You can complete each module final test 1 time, after which you can improve your grade 1 time. It is important to note that the final score will be based on the result of the last completion for each module (the last result of the test will be used)',
			},
			4: {
				title: 'One-pager',
				alt: 'one-pager',
				text: 'After you have put the tests behind you, you will need to upload a one-pager of a business idea to the platform at the end of the semester. You may present your own idea or choose to come up with a solution to a challenge presented by one of the HSUP partners',
			},
			5: {
				title: 'Dashboard',
				alt: 'plan',
				text: 'On the dashboard, you can view your results to date and your advancement in the course. Monitor your progress weekly and continue learning at your own pace',
			},
			6: {
				title: 'Execution time',
				alt: 'time',
				text: 'According to our estimation completing the course material will take approximately 2 hours per module, 10-15 hours in total, depending on your individual pace. We recommend that you do not wait until the last day of the semester to finish the modules, but we will notify you as well about the upcoming deadlines. You will also be able to see when each module would become unlocked, so you will be able to progress in stages',
			},
			7: {
				title: 'The Events menu is the world of possibilities.',
				alt: 'discovery',
				text: 'Check out what is happening in the startup scene both in Hungary and abroad. You would like to attend a meetup? Or apply for an acceleration programme? Maybe you\'re interested in a corporate training? Visit the Events section regularly as opportunities are constantly being updated!',
			},
			8: {
				title: 'Compete for your university',
				alt: 'race',
				text: 'In the spirit of the team competition found in the startup world, at HSUP, we endeavor to ensure that you do not face challenges alone. University rankings are determined by the average scores of students from each institution. Therefore, regardless of whether your course has 30 or 300 students, the highest achievers will be recognized',
			},
			9: {
				title: 'Mobile application',
				alt: 'mobile',
				text: 'For easy access to the course material at any time, download the HSUP app via App Store or Google Play Store and enable notifications to stay up to date with the latest information',
			},
			10: {
				title: 'Got a question?',
				alt: 'contact',
				text: "While using the platform, you may have questions or comments about a feature. If a button gets stuck, a page doesn't open, or you encounter any other problems with the use of the platform, do not hesitate to contact HSUP's customer support team  at info.hsup@niu.hu, we will answer you during working hours",
			},
			11: {
				title: 'Personality test',
				alt: 'discovery',
				text: 'Before we get started, we\'re going to give you a test to see what your greatest strengths are. After all, startups are team-based, so it\'s important to know each other\'s most important skills',
			},
		},
	},
	secondSemester: {
		semester: 'Second semester',
		topics: {
			owner: {
				1: {
					title: 'Welcome to the second semester of HSUP!',
					alt: 'welcome',
					text: 'The previous semester of HSUP was about presenting your new startup ideas, but this semester the focus will be on implementing the ideas and building the team!',
				},
				2: {
					title: 'Your project',
					alt: 'project',
					text: 'As the first step of the semester, you need to create a description of your project to attract team members. Once you are done with the description, you need to publish your project so other students can apply to join.',
				},
				3: {
					title: 'Your team',
					alt: 'team',
					text: 'You will evaluate the students applying to your team to determine if they fit into your project. If someone\'s profile shows they would be a perfect team member for your project, you can also invite them. Your team can consist of min. 2 and max. 3 people. Once someone is accepted into your team, you cannot make changes.',
				},
				4: {
					title: 'Mentors',
					alt: 'mentor',
					text: 'To ensure your team\'s success, a mentor will help guide your work during the semester. You need to apply to mentors with a short motivation letter to convince them to support your work throughout the semester.',
				},
				5: {
					title: 'Progress report',
					alt: 'report',
					text:
						'At the end of the semester, you need to upload a progress report based on a template, where you summarize your work during the semester.\n\n' +
						'Upload deadline:\n\n' +
						'#### {{deadline}}',
				},
				6: {
					title: 'Modules',
					alt: 'modules',
					text: 'In addition to developing ideas and team building, theoretical knowledge acquisition cannot be left out in the second semester either. In this semester, you can learn about the startup world through 5 modules.',
				},
				7: {
					title: "Let's get started!",
					alt: 'go',
					text: "Now that you know everything about the semester, let's get started! We wish you a successful semester! 💪",
				},
			},
			member: {
				1: {
					title: 'Welcome to the second semester of HSUP!',
					alt: 'welcome',
					text: 'The previous semester of HSUP was about presenting your new startup ideas, but this semester the focus will be on implementing the ideas and building the team!',
				},
				2: {
					title: 'Project search',
					alt: 'projekt',
					text: 'In the second semester of HSUP, you need to find your team that you will work with during the semester. Look around in the project search and choose the project that appeals to you the most!',
				},
				3: {
					title: 'Project application',
					alt: 'application',
					text: 'You can apply to multiple projects at the same time, but you can only join one project team. If you have multiple pending project applications, you will join the team whose project owner accepts your application first.',
				},
				4: {
					title: 'Mentors',
					alt: 'mentor',
					text: 'To ensure your team\'s success, a mentor will help guide your work during the semester. Project owners can apply to mentors, but you can help them with this! Look around in the mentor search and if you find the right mentor, make a suggestion to the project owner.',
				},
				5: {
					title: 'Modules',
					alt: 'modules',
					text: 'In addition to developing ideas and team building, theoretical knowledge acquisition cannot be left out in the second semester either. In this semester, you can learn about the startup world through 5 modules.',
				},
				6: {
					title: "Let's get started!",
					alt: 'go',
					text: "Now that you know everything about the semester, let's get started! We wish you a successful semester! 💪",
				},
			},
		},
	},
};
