import { Box, LinearProgress, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LAYOUT_TYPES } from 'app/constants';
import { useRouterGuard } from 'app/guards/useRouterGuard';
import { loaderSelector } from 'app/store/slices/loader.slice';
import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';

const useStyles = makeStyles((theme) => ({
	content: {
		width: '100vw',
		height: '100vh',
		display: 'flex',
		backgroundColor: theme.palette.background.default,
	},
}));

const AuthLayout = ({ route }) => {
	const classes = useStyles();
	const { isLoading } = useSelector(loaderSelector);
	const theme = useTheme();
	const isAboveLg = useMediaQuery(theme.breakpoints.up('lg'));

	useRouterGuard(LAYOUT_TYPES.GUEST);

	return (
		<main className={classes.content}>
			{isAboveLg && (
				<Box
					sx={{
						flex: 1,
						width: '100%',
						height: '100%',
						backgroundColor: theme.palette.primary.mainDarker,
						backgroundImage: 'url(images/HSUP-pattern.png)',
						backgroundSize: 'cover',
						backgroundPosition: 'center',
					}}
				/>
			)}
			<Suspense fallback={<LinearProgress />}>
				{isLoading && <LinearProgress />}
				<Box sx={{ flex: 1 }}>{renderRoutes(route.routes)}</Box>
			</Suspense>
		</main>
	);
};

AuthLayout.propTypes = {
	route: PropTypes.shape({
		path: PropTypes.string,
		component: PropTypes.func,
		routes: PropTypes.array,
	}).isRequired,
};

export default AuthLayout;
