export default {
	title: 'Newsfeed',
	subtitle:
		'News, deadlines, reminders and everything you need to know. Welcome to the HSUP newsfeed',
	addNews: {
		title: 'Do you have an important piece of information to share with students?',
		subtitle: 'Click on the "Post news" button',
		publishButton: 'Post news',
	},
	noNews: 'No news in the newsfeed at the moment',
	deleteDialog: {
		title: 'Are you sure you want to delete the news?',
		subtitle: 'Are you sure you want to delete the news? This action cannot be undone.',
		submitButton: 'Delete news',
	},
	dialog: {
		subtitle: 'Fill this form to upload a new news item',
		newsDataFormGroupTitle: 'News data',
		newsTitle: 'News title',
		newsTitlePlaceholder: 'Dear students...',
		newsContent: 'News text',
		newsContentPlaceholder: 'Dear students...',
		newsPicture: 'Upload image (optional)',
		newsPictureFieldTitle: 'The image accompanying the news item',
		newsPictureFieldSubtitle: 'Click or drag the image to upload it',
		newsPictureFormatText: 'Uploadable formats:',
		newsFilterFormGroupTitle: 'Who would you like to send the news to?',
		newsFilterFormGroupSubtitle: 'Only the user group marked here will receive the news.',
		newsChooseUniversity: 'Choose a university',
		newsChooseRole: 'Choose user type',
		sendToMentors: 'Send to mentors',
	},
	createDialog: {
		title: 'Upload new news',
		submitButton: 'Publish news',
	},
	updateDialog: {
		title: 'Edit news',
		submitButton: 'Publish changes',
	},
	toast: {
		addError: 'Failed to upload news :(',
		addSuccess: 'The news was uploaded successfully',
		updateError: 'Could not modify the news',
		updateSuccess: 'The news was successfully modified',
		deleteError: 'Failed to delete the news',
		deleteSuccess: 'The news was deleted successfully',
	},
	rolesPlural: {
		student: 'Students',
		university: 'Course coordinator',
		mentor: 'Mentors',
	},
	validation: {
		checkboxGroupNoRolesChosen: 'At least one user group must be selected',
	},
};
