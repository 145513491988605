const startupLabEvents = {
	header: {
		title: 'Events',
		subtitle:
			'We\'ve gathered the most exciting local challenges, competitions, and opportunities for you. Click for details and learn more.',
	},
	noResult: {
		text1: 'No event found with the specified criteria.',
		text2: 'Please expand the filtering criteria.',
	},
	upload: {
		header: 'Upload new event',
		subtitle: 'Click on "upload new event" to upload an event',
		submitBtn: 'Submit new event',
		toastSuccessMsg: 'Successful upload',
	},
	edit: {
		header: 'Edit event',
		submitBtn: 'Publish change',
		toastSuccessMsg: 'Successful modification',
	},
	form: {
		header: 'Upload new event!',
		titleFieldTitle: 'Event title',
		subtitleFieldTitle: 'Event subtitle',
		descriptionFieldTitle: 'Event text',
		categoryFieldTitle: 'Select category',
		emailFieldTitle: 'Contact email address',
		linkFieldTitle: 'Website',
		startDateFieldTitle: 'Start date of event',
		endDateFieldTitle: 'End date of event',
		logoFieldTitle: 'Event/Partner logo',
		logoFieldSubTitle: 'Click or drag the image here to change it',
		pictureFormatText: 'Uploadable formats:',
		cancelLabel: 'Cancel',
		okLabel: 'Selection',
		backBtn: 'Cancel',
		validationMaxLength: 'You can write max {{count}} characters.',
		validateCategoriesMinCount: 'Enter at least one!',
		validateCategoriesMaxCount: 'Enter maximum five!',
		validationEmailRequired: 'Email validation required!',
		validationStartDateRequired: 'Start date required!',
		validationEndDateRequired: 'End date required!',
		validateEndDate: 'The end date cannot be earlier than the start date!',
		validationBadFormat: 'Bad format',
	},
	delete: {
		title: 'Are you sure you want to delete it?',
		confirmBtn: 'Delete',
		backBtn: 'Cancel',
		toastSuccessMsg: 'Deleted successfully',
	},
	list: {
		expiresToday: 'Today',
		remainingText: 'days left',
		expiredText: 'This event has expired',
		detailsText: 'go to event',
	},
	detail: {
		contact: 'Contact',
		description: 'Event description',
		subtitle: 'Event subtitle',
		link: 'event details',
		linkEmail: 'Contact',
		deadline: 'Deadline',
	},
};

export default startupLabEvents;
