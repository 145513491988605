import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	root: {
		background: 'transparent',
		height: '100%',
		overflowY: 'auto',
		width: 250,
		minWidth: 250,
		flex: '0 0 auto',
	},
	content: {
		height: '100%',
	},

	navigation: {
		background: '#fff',
		height: 'calc(100% - 75px)', // height of logoContainer (35px) + 2 * 20px padding
	},
	sidebarList: {
		width: '100%',
	},
}));

export default useStyles;
