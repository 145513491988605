export default {
	tableHeaders: {
		name: 'Full name',
		email: 'E-mail address',
		university: 'Institution',
		neptun: 'Neptun',
		decline: 'Decline',
		status: 'Status',
		accept: 'Accept',
	},
	status: {
		accepted: 'Accepted',
		confirmed: 'Accepted',
		rejected: 'Rejected',
		notConfirmed: 'Waiting',
	},
	confirmationDialog: {
		accept: {
			title: 'Accept registration',
			subtitle: 'Are you sure you want to accept this registration?',
			cancel: 'Cancel',
			submit: 'Accept',
		},
		reject: {
			title: 'Reject registration',
			subtitle: 'Are you sure you want to reject this registration?',
			cancel: 'Cancel',
			submit: 'Reject',
		},
	},
};
