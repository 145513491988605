import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { genericRefreshToast } from 'app/store/toast';
import { ProjectMember } from '../types/ProjectMember';

export type ProjectMemberFinderFilters = {
	search?: string;
	personalities?: string[];
	focuses?: string[];
};

type ProjectMemberFinderInitialState = {
	isPending: boolean;
	filters: ProjectMemberFinderFilters;
	projectMembers: ProjectMember[];
	pagination: {
		totalCount: number;
		pageNumber: number;
		pageCount: null | number;
	};
	endReached: boolean;
};

const initialState: ProjectMemberFinderInitialState = {
	isPending: false,
	projectMembers: [],
	filters: {
		search: '',
		personalities: [],
		focuses: [],
	},
	pagination: {
		totalCount: 0,
		pageNumber: 1,
		pageCount: null,
	},
	endReached: false,
};

type CallFindProjectMembersApiParams = {
	thunkApi: { getState: () => unknown };
	customFilters?: ProjectMemberFinderFilters;
	customPageNumber?: number;
};

const callFindProjectMembersApi = async (params: CallFindProjectMembersApiParams) => {
	const { thunkApi, customFilters, customPageNumber } = params;
	const { getState }: any = thunkApi;
	const state = getState().projectMemberFinder;

	const pageNumber = customPageNumber || state.pagination.pageNumber;

	const filters = customFilters || state.filters;

	const response = await axiosService.instance.post(`/find-member/all`, {
		...filters,
		pageNumber,
	});

	return response.data;
};

export type FindProjectMembersWithFiltersParams = {
	filters?: ProjectMemberFinderFilters;
	pageNumber?: number;
	onSuccess?: () => void;
};

export const findProjectMembers = createAsyncThunk(
	'find-project-member/findProjectMembersWithFilter',
	async (params: FindProjectMembersWithFiltersParams, thunkApi) => {
		const { filters, pageNumber: customPageNumber, onSuccess } = params;

		const { getState, dispatch }: any = thunkApi;
		const state = getState().projectMemberFinder;
		// prevent multiple search requests
		if (state.isPending) {
			return null;
		}
		// if action triggered by filter update, save it in store
		// on page change, filters are sent from store
		if (params?.filters) {
			dispatch(updateCachedFilters(params?.filters));
		}
		dispatch(startPending());

		// Merge already existing search or checkbox filters
		const mergedFilters = {
			search: filters?.search ?? state.filters.search,
			focuses: filters?.focuses ?? state.filters.focuses,
			personalities: filters?.personalities ?? state.filters.personalities,
		};

		try {
			const response = await callFindProjectMembersApi({
				thunkApi,
				customFilters: mergedFilters,
				customPageNumber,
			});

			if (onSuccess) {
				onSuccess();
			}

			return {
				...response,
				filters: mergedFilters,
			};
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const projectMemberFinderSlice = createSlice({
	name: 'findProjectMember',
	initialState,
	reducers: {
		startPending(state) {
			state.isPending = true;
		},
		updateCachedFilters(state, action) {
			state.filters = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(findProjectMembers.fulfilled, (state, action) => {
			const { projectMembers, pagination, filters } = action.payload;

			state.projectMembers = projectMembers;
			state.pagination = pagination;
			state.filters = filters;
			state.isPending = false;
			state.endReached = state.projectMembers.length === pagination.totalCount;
		});
	},
});

export const { startPending, updateCachedFilters } = projectMemberFinderSlice.actions;

export const selectProjectMembers = (state) => state.projectMemberFinder.projectMembers;
export const projectMemberFinderIsRefreshing = (state) => state.projectMemberFinder.isRefreshing;
export const selectProjectMemberFinderSearch = (state) => state.projectMemberFinder.filters?.search;
export const selectProjectMemberFinderSelectedFocuses = (state) =>
	state.projectMemberFinder.filters?.focuses ?? [];
export const selectProjectMemberFinderSelectedPersonalities = (state) =>
	state.projectMemberFinder.filters?.personalities ?? [];
export const selectProjectMemberFinderIsPending = (state) => state.projectMemberFinder.isPending;

export const selectMemberFinderResultSize = (state) =>
	state.projectMemberFinder.pagination.totalCount ?? null;

export const selectMemberFinderPaginationInfo = (state) => state.projectMemberFinder.pagination;

export default projectMemberFinderSlice.reducer;
