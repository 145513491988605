import palette from '../palette';
import typography from '../typography';

export default {
	root: {
		...typography.caption,
		color: 'red',
		display: 'table-head',
		backgroundColor: palette.secondary.light,
	},
};
