import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from 'app/i18n';
import axiosService from 'app/store/axiosService';
import { getStartupLabEvents } from 'app/store/slices/startup-lab-event/startupLabEvents.slice';
import type { StartupLabEvent } from 'features/startup-lab-event/type/StartupLabEvent.type';
import { genericRefreshToast, successToast } from '../../toast';

const baseUrl = '/startup-lab';

// eslint-disable-next-line no-unused-vars
type LoadingSetter = (value: boolean) => void;

type CreateStartupLabEventParams = {
	startupLabEvent: StartupLabEvent;
	id: string;
	setIsLoading: LoadingSetter;
	handleClose: () => void;
};

export const createStartupLabEvent = createAsyncThunk(
	'startup-lab-events/create',
	async (params: CreateStartupLabEventParams, thunkApi) => {
		const { dispatch } = thunkApi;
		const { startupLabEvent, handleClose, setIsLoading } = params;

		try {
			const response = await axiosService.instance.post(baseUrl, startupLabEvent);

			// Referesh pagination's state
			dispatch(getStartupLabEvents({}));

			setIsLoading(false);
			successToast(i18n.t('startupLabEvents.upload.toastSuccessMsg'));
			handleClose();

			return response.data.startupLabEvent as StartupLabEvent;
		} catch (error) {
			setIsLoading(false);
			genericRefreshToast();

			throw error;
		}
	}
);

type UpdateStartupLabEventParams = {
	startupLabEvent: StartupLabEvent;
	id: string;
	setIsLoading: LoadingSetter;
	handleClose: () => void;
};

export const updateStartupLabEvent = createAsyncThunk(
	'startup-lab-events/update',
	async (params: UpdateStartupLabEventParams) => {
		const { startupLabEvent, id, handleClose, setIsLoading } = params;

		try {
			const response = await axiosService.instance.put(`${baseUrl}/${id}`, startupLabEvent);
			successToast(i18n.t('startupLabEvents.edit.toastSuccessMsg'));
			handleClose();
			return response.data.startupLabEvent as StartupLabEvent;
		} catch (error) {
			genericRefreshToast();
			throw error;
		} finally {
			setIsLoading(false);
		}
	}
);

type DeleteStartupLabEventParams = {
	startupLabEventId: string;
	setLoading: LoadingSetter;
};
export const deleteStartupLabEvent = createAsyncThunk(
	'startup-lab-events/delete',
	async (params: DeleteStartupLabEventParams, thunkApi) => {
		const { dispatch } = thunkApi;
		const { startupLabEventId, setLoading } = params;

		try {
			await axiosService.instance.delete(`${baseUrl}/${startupLabEventId}`);

			// Referesh pagination's state
			dispatch(getStartupLabEvents({}));

			successToast(i18n.t('startupLabEvents.delete.toastSuccessMsg'));
			return { id: startupLabEventId };
		} catch (error) {
			genericRefreshToast();
			throw error;
		} finally {
			setLoading(false);
		}
	}
);
