export default {
	header: {
		title: 'Student list',
		subtitle: 'Here is the list of active HSUP students',
	},
	export: 'Export (Excel)',
	edit: {
		title: 'Edit student information',
		fullName: 'Full name',
		neptunCode: 'Neptun code',
		email: 'E-mail address',
		university: 'University',
		cancel: 'Cancel',
		saveChanges: 'Save changes',
	},
	noResult: {
		text1: 'No student found with the specified criteria',
		text2: 'Please expand the filtering criteria.',
	},
	tableHeaders: {
		name: 'Name',
		email: 'Email address',
		university: 'University',
		points: 'Points',
		grade: 'Grade',
	},
};
