const memberI18nHu = {
	list: {
		header: {
			title: 'Project member search',
			STUDENT: {
				subtitle: ' Find the students who best fit your team.!',
			},
			OTHER: {
				subtitle: '',
			},
		},
		noResult: {
			text1: 'No students were found matching the given criteria..',
			text2: 'Please broaden the filtering criteria.',
		},
		apply: {
			title: 'Are you sure you want to invite the student to your team? ',
			lead: 'The invitation cannot be revoked. Once the student responds to the invitation, we will notify you!',
			submit: 'I am inviting them to my team. ',
		},
		applySuccess: {
			title: 'Invitation sent',
			lead: 'As soon as the student responds to your application, we will notify you',
			button: 'OK',
		},
	},
	details: {
		header: {
			title: 'Project member ',
		},
		card: {
			title: 'Congratulations, you now have a mentor!',
			subtitle: "You can find their contact details in the 'Team' section.",
			primaryButton: "I'll check my team ",
		},
		button: {
			invite: "I'll invite them to my team ",
			back: 'Project member search',
		},
		sections: {
			shortIntroduction: 'Short introduction',
			aboutMe: 'About me',
			interests: 'Interests',
			personality: 'Personality',
		},
	},
	invited: {
		header: {
			title: 'Invitations',
			subtitle:
				'Here you can see the students you have previously invited to your team.',
		},
		noResult: {
			title: 'No invitations',
			lead: ' If you find a student suitable based on their profile, you can invite them to your project team to work together throughout the semester. Browse the student search and find the most suitable candidates!',
			button: 'Student search',
		},
	},
	invitations: {
		header: {
			title: 'Invitations',
			subtitle:
				' Here you can see the projects where the project owner would like you to join their team. ',
		},
		noResult: {
			title: 'No invitations',
			lead: "If a project owner finds your profile suitable, they can invite you to their project team. The projects where you've received an invitation will appear here.",
		},
	},
};

export default memberI18nHu;

