import palette from '../palette';

export default {
	root: {
		border: `0px solid ${palette.text.muted}`,
		backgroundColor: 'white',
		borderRadius: 5,
		'&:hover': {
			backgroundColor: 'white',
		},
		'&$focused': {
			borderColor: palette.primary.main,
			backgroundColor: 'white',
		},
		'&$error': {
			borderColor: palette.error.main,
			backgroundColor: 'white',
		},
	},
};
