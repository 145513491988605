/* eslint-disable react/forbid-prop-types */
import { LinearProgress } from '@material-ui/core';
import { LAYOUT_TYPES } from 'app/constants';
import { useRouterGuard } from 'app/guards/useRouterGuard';
import { loaderSelector } from 'app/store/slices/loader.slice';
import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';

export default function PersonalityTestLayout({ route }) {
	const { isLoading } = useSelector(loaderSelector);

	useRouterGuard(LAYOUT_TYPES.PERSONALITY);

	return (
		<Suspense fallback={<LinearProgress />}>
			{isLoading && <LinearProgress />}
			<main>{renderRoutes(route.routes)}</main>
		</Suspense>
	);
}

PersonalityTestLayout.propTypes = {
	route: PropTypes.shape({
		path: PropTypes.string,
		component: PropTypes.func,
		routes: PropTypes.array,
	}).isRequired,
};
