export default {
	title: 'One-pager',
	admin: {
		pageTitle: {
			onePager: 'One-pager',
			corporateProblems: 'Partner challenges',
		},
		title: 'Submitted one-pagers',
		subtitle:
			'Here, you can download the one-pages submitted by the students. Use the filter on the right to sort between students\' own project ideas and partner challenges for each university.',
		rate: {
			description: 'uploaded one-pager.',
			student: 'student',
		},
		list: {
			studentName: 'Name',
			type: 'Type',
		},
		ownProblem: 'project idea',
		notUploaded: 'Not uploaded',
		download: 'Download',
	},
	student: {
		title: 'One pager',
		subtitle:
			'Select one of the following challenges and develop a possible solution in the form of a one-pager',
		progress: {
			notCompletedTitle: 'Complete all modules first!',
			notCompletedContent:
				'To upload a one-pager, you must first complete all course materials and module exams.',
			alreadyCompletedTitle: 'Congratulations! You have already submitted a one-pager this semester!',
			alreadyCompletedContent: 'Date of submission: {{submittedDate}}',
		},
		ownOnePager: {
			title: 'Upload the one-pager of your own project idea',
			oneliner:
				'Do you have a brilliant business idea that doesn\'t relate to any of the partner challenges? Awesome! Click here to upload.',
		},
		detailCard: {
			descriptionTitle: 'Challenge description',
			docsListTitle: 'Related Documents',
			freeSeatText: 'There are {{count}}  free spots left',
			submitSection: {
				title: 'Would you like to participate in a partner challenge?',
				content: 'Submit a one-pager for the partner challenge',
				buttonLabel: 'Submit one-pager',
			},
			own: {
				title: 'Upload the one-pager of your own project idea',
				description: `At the end of the semester apart from successfully completing the module tests you must submit a one-pager of a business idea. You need to decide whether to present your own idea or to solve a partner challenge. If you continue the course into the next semester and your one-pager is selected, you will work on the submitted idea and form a team

A one-pager, also known as an investor summary, is usually the initial document your potential investor receives about your project.

A good one-pager:
- Summarizes the entire business opportunity and concept.
- Based on a mature, well-developed idea.
- Concise format, limited to one page, presenting a comprehensive description and all key elements.
- Designed to captivate attention with a unique and sophisticated appearance.
- Emphasizes business potential and high return on investment, minimizing technical solution details.
- In addition to summarizing the current status and progress of the startup, it also includes the team's funding request from the investor`,

submitSection: {
					title: 'Have a great idea of your own?',
					content: 'Submit your one-pager based on your own business idea',
					buttonLabel: 'Submit one-pager',
				},
			},
		},
		listItem: {
			freeSeats: 'free spot',
			nextBtn: 'Proceed to problem',
		},
		dialog: {
			upload: {
				title: 'Upload one-pager (5MB)',
				subtitlePostFix: 'partner challenges',
				personal: 'Individual',
				fileTitle: 'upload one-pager',
				uploadPlaceholder: 'Click or drag the file here to upload',
				formatsText: 'Formats to upload:',
				upload: 'upload one-pager',
			},
			confirm: {
				title: 'Are you sure you want to submit the final version of your one-pager?',
				subtitle: 'Remember!',
				text: 'You can only upload one one-pager: either your own idea or a partner challenge one-pager designed to address a corporate problem. Your one-pager cannot be modified later.',
			},
		},
		onboarding: {
			title: 'One-pager',
			subtitle: 'Everything you need to know about the one-pager',
			cards: {
				1: {
					title: 'One-pager',
					text: 'After you have put the tests behind you, you will need to upload a one-pager of a business idea to the platform at the end of the semester. You may present your own idea or choose to come up with a solution to a challenge presented by one of the HSUP partners',

					imageAlt: 'design',
				},
				2: {
					title: 'The structure of a one-pager',
					text: 'If you need assistance with the structure and content of your one-pager while writing it, refer to the relevant section in the course materials.',
					imageAlt: 'idea',
				},
				3: {
					title: 'One-pager about your idea',
					text: 'Upon completing the final module exams, you are required to submit a one-pager to successfully complete the semester. If you have your own business idea, select the Upload the one-pager of your own project idea option to present it.',
					imageAlt: 'idea',
				},
				4: {
					title: 'Partner challenge one-pager',
					text: 'If you decide not to present your own idea in a one-pager after the final module exams, explore the partner challenges in the one-pager section. If you have a solution for one of the challenges, create a one-pager and upload it to the challenge of your choice.',
					imageAlt: 'corporate',
				},
				5: {
					title: 'Attention!',
					text: 'At the end of the semester apart from successfully completing the module tests you must submit a one-pager of a business idea. You need to decide whether to present your own idea or to solve a partner challenge. If you continue the course into the next semester and your one-pager is selected, you will work on the submitted idea and form a team.',
					imageAlt: 'attention',
				},
			},
		},
	},
	sidebar: {
		structure: {
			title: 'One-pager structure',
			required: {
				title: 'Mandatory elements',
				oneLine: '"OneLiner" - introduction in one sentence',
				problem: 'Identified problem',
				solution: 'Your solution to the problem',
				market: 'Introduction of the market',
				competitor: 'Competitor analysis',
				corporate: 'Corporate competitiveness',
				business: 'Business model',
				founders: 'The founders and the team',
			},
			bonus: {
				title: 'Bonus items',
				marketing: 'Marketing strategy',
				finance: 'Financing, utilization',
				milestones: 'Milestones',
			},
		},
		dontForget: {
			title: 'Remember',
			content: 'You can only upload one one-pager: either your own idea or a partner challenge one-pager designed to address a corporate problem. Your one-pager cannot be modified later.',
		},
		uploadSteps: {
			title: 'Steps of uploading the one-pager',
			steps: {
				choose: 'Choose from the corporate challenges or develop your own business idea',
				make: 'Prepare your one-pager for the identified problem',
				upload: 'Upload your final one-pager to the right place',
			},
		},
	},
	toast: {
		addError: 'Failed to upload one-pager :(',
	},
	successModal: {
		title: 'Successful upload',
		text: 'Congratulations! You have successfully uploaded your one-pager for the semester!',
	},
};
