import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from 'app/i18n';
import axiosService from '../axiosService';
import { errorToast, genericRefreshToast, successToast } from '../toast';

export const fetchCorporateProblems = createAsyncThunk(
	'corporate-problems/fetchMore',
	async ({ from, count, setAllowLoadMore }) => {
		try {
			const { data } = await axiosService.instance.get(
				`/corporate-problems?from=${from}&count=${count}`
			);

			if (data.length !== 0) {
				setAllowLoadMore(true);
			}
			return data;
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const fetchCorporateProblemDetail = createAsyncThunk(
	'corporate-problems/fetchSingle',
	async (id) => {
		try {
			const response = await axiosService.instance.get(`/corporate-problems/${id}`);
			return response.data;
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const fetchCorporateProblemTitles = createAsyncThunk(
	'corporate-problems/fetchTitles',
	async () => {
		try {
			const { data } = await axiosService.instance.get(`/corporate-problems/fetchTitles`);

			return data;
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const addCorporateProblem = createAsyncThunk(
	'corporate-problems/add',
	async ({ formData, onSuccess, setPublishing }) => {
		try {
			const { data } = await axiosService.instance.post('/corporate-problems', formData);
			setPublishing(false);
			onSuccess();
			return data;
		} catch (error) {
			setPublishing(false);
			errorToast(i18n.t('corporateProblems.toast.addError'));
			throw error;
		}
	}
);

export const updateCorporateProblem = createAsyncThunk(
	'corporate-problem/update',
	async ({ id, formData, onSuccess, setPublishing }) => {
		try {
			const { data } = await axiosService.instance.put(`corporate-problems/${id}`, formData);
			successToast(i18n.t('corporateProblems.toast.updateSuccess'));
			setPublishing(false);
			onSuccess();
			return data;
		} catch (error) {
			setPublishing(false);
			errorToast(i18n.t('corporateProblems.toast.updateError'));
			throw error;
		}
	}
);

export const deleteCorporateProblem = createAsyncThunk(
	'corporate-problem/delete',
	async ({ id, onSuccess }) => {
		try {
			const { data } = await axiosService.instance.delete(`corporate-problems/${id}`);
			successToast(i18n.t('corporateProblems.toast.deleteSuccess'));
			onSuccess();
			return data;
		} catch (error) {
			errorToast(i18n.t('corporateProblems.toast.deleteError'));
			throw error;
		}
	}
);

export const corporateProblemsSlice = createSlice({
	name: 'corporateProblems',
	initialState: {
		isLoading: false,
		data: [],
		singleItemData: null,
		titleAndIdList: [],
		showSuccessModal: false,
	},
	reducers: {
		resetCorporateProblemsData(state) {
			state.data = [];
		},
		setShowSuccessModal: (state, action) => {
			state.showSuccessModal = action.payload;
		},
	},
	extraReducers: {
		[fetchCorporateProblems.pending]: (state) => {
			state.isLoading = true;
		},
		[fetchCorporateProblems.fulfilled]: (state, action) => {
			const fetched = action.payload;
			state.data = state.data.concat(fetched);
			state.isLoading = false;
		},
		[fetchCorporateProblemDetail.pending]: (state) => {
			state.singleItemData = null;
		},
		[fetchCorporateProblemDetail.fulfilled]: (state, action) => {
			const singleItem = action.payload;
			state.singleItemData = singleItem;
		},
		[fetchCorporateProblemTitles.fulfilled]: (state, action) => {
			const titleAndIdList = action.payload;
			state.titleAndIdList = titleAndIdList;
		},
		[addCorporateProblem.fulfilled]: (state, action) => {
			const added = action.payload;
			state.data = [added].concat(state.data);
			state.showSuccessModal = true;
		},
		[updateCorporateProblem.fulfilled]: (state, action) => {
			const updated = action.payload;
			const newData = state.data.map((record) =>
				record._id === updated._id ? updated : record
			);
			state.data = newData;
		},
		[deleteCorporateProblem.fulfilled]: (state, action) => {
			const { id, ok } = action.payload;
			if (ok) {
				state.data = state.data.filter((record) => record._id !== id);
			}
		},
	},
});

export const selectCorporateProblemTitles = ({ corporateProblems: { titleAndIdList } }) =>
	titleAndIdList;

export const selectCorporateProblems = ({ corporateProblems: { data, isLoading } }) => ({
	data,
	isLoading,
});

export const selectSingleCorporateProblem = ({ corporateProblems: { singleItemData } }) =>
	singleItemData;

export const selectShowSuccessModal = (state) => state.corporateProblems.showSuccessModal;

export const { setShowSuccessModal } = corporateProblemsSlice.actions;

export const { resetCorporateProblemsData } = corporateProblemsSlice.actions;
export default corporateProblemsSlice.reducer;
