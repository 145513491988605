import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	item: {
		display: 'block',
		paddingTop: 0,
		paddingBottom: 0,
	},
	itemLeaf: {
		display: 'flex',
		paddingTop: 0,
		paddingBottom: 0,
	},
	buttonLeaf: {
		justifyContent: 'flex-start',
		textTransform: 'none',
		width: '100%',
		borderRadius: 0,
		border: 'none',
		paddingLeft: 20,
		padding: '12px 24px',
		color: theme.palette.primary.dark,
		fontWeight: 600,
		fontSize: '16px',
		lineHeight: '24px',
	},
	icon: {
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(1),
		color: theme.palette.primary.main,
	},
	label: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: 'auto',
	},
	active: {
		color: theme.palette.primary.main,
		'& $icon': {
			color: theme.palette.ternary.main,
		},
		'&::before': {
			position: 'absolute',
			content: "''",
			display: 'block',
			left: 0,
			height: '100%',
			width: 4,
			backgroundColor: theme.palette.ternary.main,
		},
	},
}));

export default useStyles;
