export default {
	title: 'Profile settings',
	subtitle: 'Complete your profile information so project owners can learn more about you.',
	profile: {
		emailField: 'Email address',
		changeAvatarBtn: 'Change profile picture',
		neptun: 'Neptun code',
		userName: 'Username'
	},
	changePassword: {
		title: 'Change password',
		currentPassword: 'Current Password',
		newPassword: 'New Password',
		newPasswordAgain: 'New Password again',
	},
	personality: {
		title: 'Personality',
		changePersonality: 'Change personality',
		successMessage: 'Changed successfully!',
		errorMessage: 'Unsuccessful modification!',
	},
	projectMember: {
		title: 'Introduction',
		subtitle: 'Complete your profile information so project owners can learn more about you ',
		submit: 'Save changes',
		success: 'Saved',
		fail: 'Unsuccessful modification',
		introduction: {
			title: 'Introduction',
			oneLiner: 'Striking one-liner',
			introduction: 'Short introduction',
		},
		social: {
			title: 'Social media links',
			facebook: 'Facebook link',
			linkedin: 'Linkedin link',
		},
		focuses: {
			title: 'Select interest',
			subtitle: 'You can choose up to 5!',
			caption: 'Selected elements',
			focusSelectModal: {
				title: 'Select interest',
				subtitle: 'You can choose up to 5!',
				submit: 'Save changes',
			},
			listFocuses: {
				empty: 'No industrial focus selected',
			},
			openModalButton: 'Select elements',
		},
	},
	mentor: {
		title: 'Edit profile',
		contact: 'Contact information',
		publishText: 'Project owners can only apply to you if you allow your profile to appear in the mentor search!',
		requiredFields: 'Fields marked with * are required',
		saveChanges: 'Save changes',
		publishDialogTitle: "Are you sure you don't want to publish your profile?",
		publishDialogDescription:
			'If the profile is not published, it will not appear in the mentor search. Project owners will not be able to contact you',
		publishDialogSaveNoPublish: 'Save only',
		publishDialogSaveAndPublish: 'Publish and Save',
		contactTitle: 'Contact informations',
		contactDescription:
			'The following data will only be displayed for teams whose application you have accepted',
		contactSocialMediaTitle: 'Social media links',
		contactFacebook: 'Facebook',
		contactLinkedIn: 'LinkedIn',
		contactWeb: 'Website',
		contactEmail: 'Email *',
		contactPhoneNumber: 'Phone number',
		bio: 'Professional biography *',
		bioDescription: 'Please briefly describe your professional background',
		bioTitle: 'Title, position, organisation to be displayed *',
		bioTitleDescription:
			'Please provide a brief description of the title, position or organisation you would like displayed under your name',
		specialization: 'Select a specialization',
		specializationDescription: 'You can choose more than one.',
		focus: 'Select industrial focus',
		focusDescription: 'You can choose more than one.',
		publish: 'Publication',
		publishDescription: 'Show profile in mentor search',
	},
};
