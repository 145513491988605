export default {
	dashboard: 'Dashboard',
	onepager: {
		title: 'One-pagers',
		menuItems: {
			submittedOnepagers: 'Submitted one-pagers',
			corporateProblems: 'Partner challenges',
		},
	},
	news: 'Newsfeed',
	studentOnepager: 'One-pager',
	modules: 'Modules',
	moduleList: 'Module list',
	myProject: 'My Project',
	pushNotifications: 'Mobile notifications',
	registrationConfirmation: 'Registration requests',
	myTeam: {
		title: 'My team',
		menuItems: {
			team: 'Team',
			applications: 'Applications',
		},
	},
	project: {
		title: 'Projects',
		menuItems: {
			findProject: 'Project finder',
			applications: 'My applications',
			myProject: 'My Project',
			mentoredProjects: 'Mentored projects',
			mentorInterests: 'Interests',
			invitations: 'Invitations',
		},
	},
	mentor: {
		title: 'Mentors',
		menuItems: {
			findMentors: 'Mentor list',
			applications: 'My applications',
			mentorInterests: 'Interests',
		},
	},
	mentors: 'Mentor list',
	students: 'Student list',
	settings: 'Profile settings',
	startupLabEvents: 'Events',
	onePager: 'One-pager',
	help: 'Help',
	dataExport: 'Data export',
	progressReport: 'Progress report',
	memberFinder: {
		list: 'Team member search',
		invitations: 'Invitations',
	},
};
