import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { genericRefreshToast } from 'app/store/toast';

export const uploadProgressReport = createAsyncThunk('uploadProgressReport', async (formData) => {
	try {
		const { data } = await axiosService.instance.post('/progress-report', formData);
		return data;
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const getProgressReport = createAsyncThunk('getProgressReport', async () => {
	try {
		const { data } = await axiosService.instance.get('/progress-report');
		return data;
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

const initialState = { progressReport: null, loading: true };
const progressReportUploadSlice = createSlice({
	name: 'progressReportUpload',
	initialState,
	reducers: {},
	extraReducers: {
		[getProgressReport.pending]: (state) => {
			state.loading = true;
		},
		[uploadProgressReport.fulfilled]: (state, action) => {
			state.progressReport = action.payload;
		},
		[getProgressReport.pending]: (state) => {
			state.loading = true;
		},
		[getProgressReport.fulfilled]: (state, action) => {
			state.progressReport = action.payload;
			state.loading = false;
		},
	},
});

export const selectProgressReport = (state) => ({
	progressReport: state.progressReportUpload.progressReport,
	loading: state.progressReportUpload.loading,
});

export default progressReportUploadSlice.reducer;
