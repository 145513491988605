export default {
	section: {
		extras: 'Related interactive content',
		videos: 'Video content',
		podcasts: 'Podcasts',
		relatedLinks: 'Attachments',
	},
	navigation: {
		previousLesson: 'Previous',
		nextLesson: 'Next',
		startTest: 'Start exam',
	},
	suggestion: {
		callToAction: 'Add your suggestions regarding the course material',
		placeholder: 'I suggest...',
		send: 'Send',
		thanks: 'Thank you for your feedback!',
		thanksForTheSuggestion: 'Thanks for the suggestion!',
		description:
			'All suggestions are important to us, so we can improve the quality of the course material.',
		close: 'close',
	},
};
