import { colors } from '@material-ui/core';
import palette from './palette';

export default {
	fontFamily: 'Zilla Slab',
	h1: {
		color: palette.text.primary,
		fontWeight: 700,
		fontSize: '38px',
		lineHeight: '45.6px',
	},
	h2: {
		color: palette.text.primary,
		fontWeight: 700,
		fontSize: '24px',
		lineHeight: '36px',
	},
	h3: {
		color: palette.text.primary,
		fontWeight: 600,
		fontSize: '22px',
		lineHeight: '33px',
	},
	h4: {
		color: palette.text.secondary,
		fontWeight: 600,
		fontSize: '18px',
		lineHeight: '21.6px',
	},
	h5: {
		color: palette.primary.dark,
		fontWeight: 600,
		fontSize: '16px',
		lineHeight: '19.2px',
	},
	body1: {
		color: colors.blueGrey[900],
		fontFamily: 'REM',
		fontSize: '16px',
		lineHeight: '25.6px',
	},
	caption: {
		fontFamily: 'REM',
		color: palette.text.primary,
		paragraph: '10px',
		fontWeight: 500,
		fontSize: '12px',
		lineHeight: '18px',
		letterSpacing: '1%',
		textTransform: 'uppercase',
		display: 'block',
	},
	overline: {
		fontFamily: 'REM',
		color: colors.blueGrey[300],
		fontWeight: 500,
		fontSize: '14px',
		lineHeight: '18px',
	},
	paragraph: {
		fontFamily: 'REM',
		color: palette.text.muted,
		paragraph: '10px',
		fontWeight: 400,
		fontSize: '16px',
		lineHeight: '27.2px',
	},
};
