export default {
	header: {
		title: 'Modules',
		subtitle: {
			start: 'During the semester',
			modules: 'through modules',
			end: 'you can learn how to become a successful startup entrepreneur',
		},
	},
	moduleHeader: {
		title: 'Start on your learning journey',
		description:
			'Read through the modules containing lessons, and if you are ready, take the test to see how much you have learned.',
	},
	previousSemesters: 'Previous semesters',
	subjectsAvailable: 'lessons available',
	testTitle: 'Exam',
	testAttempt: {
		correctionStart: 'You have',
		correctionEnd: 'remaining chances to improve your score',
		tryStart: 'You may try',
		tryEnd: 'more times',
		outOfAttempts: 'You have exhausted your allowed attempts',
	},
	bonus: 'extra',
};
