/* eslint-disable react/forbid-prop-types */
import { Box, Container, Hidden, LinearProgress } from '@material-ui/core';
import ProfileHeader from 'app/components/ProfileHeader';
import { Sidebar, TopBar } from 'app/components/ui';
import { LAYOUT_TYPES } from 'app/constants';
import { useRouterGuard } from 'app/guards/useRouterGuard';
import { getContext } from 'app/store/slices/context.slice';
import { loaderSelector } from 'app/store/slices/loader.slice';
import PropTypes from 'prop-types';
import { Suspense, useState } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { navbarItems } from './navbarItems';
import useStyles from './UserLayout.styles';

const getUniversityNavbar = (context) => {
	const { CURRENT_SEMESTER } = context;

	if (CURRENT_SEMESTER === 1) {
		return navbarItems.firstSemester.university;
	}
	if (CURRENT_SEMESTER === 2) {
		return navbarItems.secondSemester.university;
	}
	return [];
};

const UniversityLayout = ({ route }) => {
	const { isLoading } = useSelector(loaderSelector);
	const context = useSelector(getContext);
	const classes = useStyles();
	const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

	const handleNavBarMobileOpen = () => {
		setOpenNavBarMobile(true);
	};

	const handleNavBarMobileClose = () => {
		setOpenNavBarMobile(false);
	};

	useRouterGuard(LAYOUT_TYPES.UNIVERSITY);

	if (!context) {
		return null;
	}

	return (
		<div className={classes.root}>
			<Hidden lgUp>
				<TopBar className={classes.topBar} onOpenNavBarMobile={handleNavBarMobileOpen} />
			</Hidden>
			<div className={classes.container}>
				<Sidebar
					onMobileClose={handleNavBarMobileClose}
					openMobile={openNavBarMobile}
					currentNavbarItems={getUniversityNavbar(context)}
				/>
				<main className={classes.content}>
					<Suspense fallback={<LinearProgress className={classes.progressBar} />}>
						<Container className={classes.mainContainer} maxWidth="xl">
							<Box position="absolute" top={20} right={10}>
								<ProfileHeader />
							</Box>
							{isLoading && <LinearProgress className={classes.progressBar} />}
							{renderRoutes(route.routes)}
						</Container>
					</Suspense>
				</main>
			</div>
		</div>
	);
};

UniversityLayout.propTypes = {
	route: PropTypes.shape({
		path: PropTypes.string,
		component: PropTypes.func,
		routes: PropTypes.array,
	}).isRequired,
};

export default UniversityLayout;
