import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
	},
	progressBar: {
		position: 'fixed',
		left: 0,
		top: '0px',
		width: '100%',
		height: '2px',
	},
	topBar: {
		zIndex: 2,
		position: 'relative',
	},
	container: {
		display: 'flex',
		margin: '0',
		overflow: 'hidden',
		width: '100%',
		backgroundColor: 'white',
	},
	navBar: {
		zIndex: 3,
		width: 250,
		minWidth: 250,
		flex: '0 0 auto',
	},
	content: {
		overflowY: 'auto',
		flex: '1 1 auto',
		[theme.breakpoints.down('md')]: { paddingTop: theme.spacing(8) },
		backgroundColor: '#f5f6f9',
		minHeight: '100vh',
	},
	mainContainer: {
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
		[theme.breakpoints.down('md')]: {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
		},
	},
}));

export default useStyles;
