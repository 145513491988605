import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { genericRefreshToast, successToast } from '../toast';

export const getMyProject = createAsyncThunk('myProject/getMyProject', async () => {
	try {
		const response = await axiosService.instance.get(`/view-project/my`);
		return response.data;
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const createMyProject = createAsyncThunk('myProject/createMyProject', async () => {
	try {
		const response = await axiosService.instance.post('/manage-project/create');
		return response.data;
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const saveMyProject = createAsyncThunk('myProject/saveMyProject', async (projectDto) => {
	try {
		const response = await axiosService.instance.post('/manage-project/save-draft', projectDto);
		successToast('Successfully saved!');
		return response.data;
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const publishMyProject = createAsyncThunk(
	'myProject/publishMyProject',
	async (projectDto) => {
		try {
			const response = await axiosService.instance.post(
				'/manage-project/publish',
				projectDto
			);
			successToast('Successfully published!');
			return response.data;
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const getApplications = createAsyncThunk('myProject/getApplications', async () => {
	try {
		const response = await axiosService.instance.get('/manage-project/applications');
		return response.data;
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const acceptApplication = createAsyncThunk(
	'myProject/acceptApplication',
	async ({ applicationId, setLoading }) => {
		try {
			const response = await axiosService.instance.post(
				`/manage-project/accept-application/${applicationId}`
			);
			return response.data;
		} catch (error) {
			genericRefreshToast();
			throw error;
		} finally {
			setLoading(false);
		}
	}
);

export const rejectApplication = createAsyncThunk(
	'myProject/rejectApplication',
	async ({ applicationId, setLoading }) => {
		try {
			const response = await axiosService.instance.post(
				`/manage-project/reject-application/${applicationId}`
			);
			return response.data;
		} catch (error) {
			genericRefreshToast();
			throw error;
		} finally {
			setLoading(false);
		}
	}
);

export const myProjectSlice = createSlice({
	name: 'myProject',
	initialState: {
		data: null,
		loading: true,
		members: null,
		mentor: null,
		applications: null,
		remainingSeats: null,
	},
	extraReducers: {
		[getMyProject.pending]: (state) => {
			state.loading = true;
		},
		[getMyProject.fulfilled]: (state, action) => {
			state.data = action.payload.project;
			state.remainingSeats = action.payload.remainingSeats;
			state.members = action.payload.members;
			state.mentor = action.payload.mentor;
			state.loading = false;
		},
		[createMyProject.fulfilled]: (state, action) => {
			state.data = action.payload;
		},
		[saveMyProject.fulfilled]: (state, action) => {
			state.data = action.payload;
		},
		[publishMyProject.fulfilled]: (state, action) => {
			state.data = action.payload;
		},
		[getApplications.fulfilled]: (state, action) => {
			state.applications = action.payload.applications;
			state.remainingSeats = action.payload.remainingSeats;
		},
		[acceptApplication.fulfilled]: (state, action) => {
			const index = state.applications.findIndex((appl) => action.payload === appl._id);
			state.remainingSeats -= 1;
			state.applications.splice(index, 1);
		},
		[rejectApplication.fulfilled]: (state, action) => {
			const index = state.applications.findIndex((appl) => action.payload === appl._id);
			state.applications.splice(index, 1);
		},
	},
});

export const selectMyProject = (state) => ({
	data: state.myProject.data,
	loading: state.myProject.loading,
});
export const selectProjectRemainingSeats = (state) => state.myProject.remainingSeats;
export const selectProjectMembers = (state) => state.myProject.members;
export const selectProjectMentor = (state) => state.myProject.mentor;

export const selectApplications = (state) => state.myProject.applications;

export default myProjectSlice.reducer;
